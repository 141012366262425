import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Event,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  useBreakpoint,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { BiCalendarEdit, BiCalendarCheck, BiCalendarStar, BiCalendarX, BiSolidCalendarCheck, BiSolidCalendarEdit, BiSolidCalendarStar, BiSolidCalendarX } from 'react-icons/bi'
import { MdDelete, MdMarkEmailRead, MdOutlineDelete, MdOutlineDeleteForever, MdOutlineMarkEmailRead } from 'react-icons/md'
//extract iconBottons, or at least map eventType codes to color, icon, loader etc
import { timestampHuman } from '../../services/format.js'
const locationKeys = ['address', 'city']
const EventTileActions = ({ actionState, addToCalendar, approveEvent, archiveEvent, cancelEvent, deleteEvent, editEvent, event, eventType, readEvent }) => {
  const { colorMode } = useColorMode()
  const [hoveredBtn, setHoveredBtn] = useState(null)
  const [expandMessage, setExpandMessage] = useState(false)
  const [serviceText, setServiceText] = useState(null)

  const { isOpen: deleteOpen, onClose: onDeleteClose, onToggle: onDeleteToggle } = useDisclosure()

  const onConfirmDelete = () => {
    onDeleteClose()
    deleteEvent(event)
  }

  useEffect(() => {
    const service = event?.service
    const text = { serviceDetail: null, serviceOptions: null }
    // if (service === 'general') {
    // }
    if (service === 'portrait') {
      text.serviceDetail = `Package ${event?.portrait_package?.split('-')?.[1] || '-'}`
      text.serviceOptions = event?.portrait_options?.join(', ')?.replaceAll('-', ' ') || null
    }
    if (service === 'residential') {
      text.serviceDetail = `${event?.residential_photos} Photos`
      text.serviceOptions = event?.residential_options?.join(', ')?.replaceAll('-', ' ') || null
    }
    setServiceText(text)
  }, [event])
  return (
    <Box
      display={'flex'}
      alignItems={'end'}
      justifyContent={'space-between'}
      // ml={{ base: 'unset', sm: 'auto' }}
      mt={{ base: 4, sm: 2 }}
      minWidth={{ base: '100%', sm: 'auto' }}
    >
      {/* GENERAL INQUERY EVENTS */}
      {eventType === 'general' && (
        <>
          <Popover isLazy isOpen={deleteOpen} placement='left'>
            <PopoverTrigger>
              <IconButton
                aria-label='Delete inquiry.'
                color={'red'}
                display={'flex'}
                icon={<Icon as={hoveredBtn === 'delete' ? MdDelete : actionState?.status === 'error' ? MdOutlineDeleteForever : MdOutlineDelete} />}
                // icon={hoveredBtn === 'delete' ? <Icon as={MdDelete} /> :  <Icon as={MdOutlineDelete} />}
                isLoading={actionState?.status === 'deleting'}
                fontSize='3xl'
                ml={{ base: 0, sm: 4 }}
                onClick={onDeleteToggle}
                onMouseLeave={() => setHoveredBtn(null)}
                onMouseOver={() => setHoveredBtn('delete')}
                title={'Delete inquiry.'}
                variant={'unstyled'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton onClick={onDeleteClose} />
              <PopoverHeader color={'red'} fontWeight={'bold'}>
                CONFIRM!
              </PopoverHeader>
              <PopoverBody>
                {actionState?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to delete this message resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {actionState?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <>
                    <Text as={'p'}>Are you sure you want to permanently this message?</Text>
                    <Text as={'p'}>To archive instead, choose mark as read.</Text>
                  </>
                )}

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  mt={4}
                  colorScheme='red'
                  onClick={onConfirmDelete}
                  width={'100%'}
                  // ref={initRef}
                >
                  {actionState?.status === 'error' ? 'RETRY' : 'DELETE'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton
            aria-label='Mark inquery as read.'
            color={'green'}
            display={'flex'}
            ml={{ base: 0, sm: 4 }}
            onClick={() => archiveEvent(event)}
            icon={hoveredBtn === 'read' ? <Icon as={MdMarkEmailRead} /> : <Icon as={MdOutlineMarkEmailRead} />}
            isLoading={actionState?.status === 'archiving'}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('read')}
            fontSize='3xl'
            title={'Mark as read.'}
            variant={'unstyled'}
          />
        </>
      )}
      {eventType === 'pending' && (
        <>
          {/* TODO: MAKE COMPONENT */}

          {/* PICKYO HERE.. REMOVE EDIT FROM ICONS, 
            CAN MAKE CHANGES IN APPROVE DIALOG
            //MAKE SURE PENDING STILL HAD EDIT
              //EDIT ALL FIELDS
              //CHECKBOX SEND CONFIRMATION EMAIL
            CREATE CONFIRM ARCHIVE, DELETE MODAL
        */}
          <IconButton
            aria-label='Archive requested appointment'
            color={'red'}
            display={'flex'}
            fontSize='3xl'
            ml={{ base: 0, sm: 4 }}
            onClick={() => deleteEvent(event)}
            icon={hoveredBtn === 'delete' ? <Icon as={BiSolidCalendarX} /> : <Icon as={BiCalendarX} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('delete')}
            title={'Archive request'}
            variant={'unstyled'}
          />
          <IconButton
            aria-label='Edit requested event'
            color={'yellow'}
            display={'flex'}
            ml={{ base: 0, sm: 4 }}
            onClick={() => editEvent(event)}
            icon={hoveredBtn === 'edit' ? <Icon as={BiSolidCalendarEdit} /> : <Icon as={BiCalendarEdit} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('edit')}
            fontSize='3xl'
            title={'Edit request'}
            variant={'unstyled'}
          />

          <IconButton
            aria-label='Approve requested appointment'
            color={'green'}
            display={'flex'}
            icon={hoveredBtn === 'approve' ? <Icon as={BiSolidCalendarCheck} /> : <Icon as={BiCalendarCheck} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('approve')}
            ml={{ base: 0, sm: 4 }}
            onClick={() => approveEvent(event)}
            fontSize='3xl'
            title={'Approve request'}
            variant={'unstyled'}
          />
        </>
      )}
      {eventType === 'confirmed' && (
        <>
          <IconButton
            aria-label='Cancel Appointment'
            color={'red'}
            display={'flex'}
            icon={hoveredBtn === 'delete' ? <Icon as={BiSolidCalendarX} /> : <Icon as={BiCalendarX} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('delete')}
            ml={{ base: 0, sm: 4 }}
            onClick={() => cancelEvent(event)}
            fontSize='3xl'
            title={'Cancel Appointment'}
            variant={'unstyled'}
          />
          <IconButton
            aria-label='Edit event'
            color={'yellow'}
            display={'flex'}
            icon={hoveredBtn === 'edit' ? <Icon as={BiSolidCalendarEdit} /> : <Icon as={BiCalendarEdit} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('edit')}
            ml={{ base: 0, sm: 4 }}
            onClick={() => editEvent(event)}
            fontSize='3xl'
            title={'Edit Appointment'}
            variant={'unstyled'}
          />
          <IconButton
            aria-label='Add Appointment to Calendar'
            color={'green'}
            display={'flex'}
            icon={hoveredBtn === 'add-cal' ? <Icon as={BiSolidCalendarStar} /> : <Icon as={BiCalendarStar} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('add-cal')}
            ml={{ base: 0, sm: 4 }}
            onClick={() => addToCalendar(event)}
            fontSize='3xl'
            title={'Add to calendar'}
            variant={'unstyled'}
          />
        </>
      )}
      {eventType === 'history' && (
        <>
          <IconButton
            aria-label='Delete permanently.'
            color={'red'}
            display={'flex'}
            ml={{ base: 0, sm: 4 }}
            onClick={() => deleteEvent(event)}
            icon={hoveredBtn === 'delete' ? <Icon as={MdDelete} /> : <Icon as={MdOutlineDelete} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('delete')}
            fontSize='3xl'
            title={'Delete permanently.'}
            variant={'unstyled'}
          />
        </>
      )}

      {/* {uploadItem && (
    <IconButton
      aria-label='Upload Event'
      color={eventType.eventType === 'success' ? 'green' : eventType.eventType === 'error' ? 'red' : undefined}
      display={'flex'}
      icon={<Icon as={eventType.eventType === 'success' ? MdOutlineCloudDone : eventType.eventType === 'error' ? TbCloudOff : BiCloudUpload} />}
      isLoading={eventType.eventType === 'loading'}
      ml={4}
      onClick={() => uploadItem(event)}
      fontSize='3xl'
      title={'Upload Event'}
      variant={'unstyled'}
      _hover={{
        color: 'brand.accent',
      }}
    />
  )} */}
    </Box>
  )
}
export default EventTileActions
