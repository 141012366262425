import { useEffect, useRef, useState } from 'react'
import EventTileActions from '../event-tile-actions/index.js'
import EventTileDetails from '../event-tile-details/index.js'
import { Box, Flex, Icon, IconButton, Text, useColorMode, useDimensions } from '@chakra-ui/react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { BsPersonFillAdd } from 'react-icons/bs'
//extract iconBottons, or at least map eventType codes to color, icon, loader etc
import { timestampHuman } from '../../services/format.js'
const locationKeys = ['address', 'city', 'state', 'zipcode']
const statusColor = {
  confirmed: 'green',
  pending: 'yellow',
  general: 'brand.accent',
}

const contactDetail = contact => {
  let contactStr = ''
  if (contact?.email) contactStr += contact.email
  if (contact.email && contact.phone) contactStr += ' • '
  if (contact?.phone) contactStr += contact.phone
  return contactStr
}

const EventTile = ({ actionState, addToCalendar, addContact, approveEvent, archiveEvent, cancelEvent, deleteEvent, editEvent, event, eventType, status }) => {
  const msgTextRef = useRef()
  const { colorMode } = useColorMode()
  const [expandMessage, setExpandMessage] = useState(false)
  // const [showDetails, setShowDetails] = useState(eventType !== 'history')
  const [showDetails, setShowDetails] = useState(false)

  const contactDetailStr = contactDetail(event?.contact)

  const onDetailsToggle = () => {
    const show = !showDetails
    setShowDetails(show)
    if (status === 'general') {
      setExpandMessage(show)
    }
  }

  return (
    <Box
      boxShadow={'xl'}
      borderLeftColor={statusColor[eventType] || 'unset'}
      borderWidth='1px'
      borderLeftWidth={'2px'}
      display='flex'
      flexDir={{ base: 'column', sm: 'row' }}
      flexWrap={'wrap'}
      gap={4}
      justifyContent={{ base: 'flex-end', sm: 'flex-start' }}
      key={event?.id || 'EventTile'}
      overflowX={'hidden'}
      p={4}
      pb={2}
      position={'relative'}
    >
      {/* DETAILS COLUMN */}
      {/* CONTACT */}
      <Flex flexDir='column' justifyContent={'start'} width={'100%'}>
        <Flex justifyContent={'space-between'}>
          <Flex flexDir='column' flexGrow={'1'} justifyContent={'start'} mb={4}>
            <Flex flexDir='row' flexGrow={'1'} alignItems={'center'} justifyContent={'start'}>
              <Text fontSize='3xl' fontWeight={'bold'} lineHeight={6} m={0}>
                {`${event?.contact?.first_name || ''} ${event?.contact?.last_name || ''}`}
              </Text>
              {!event?.contact?.is_contact && (
                <IconButton alignSelf={'start'} height={'20px'} maxWidth={'20px'} icon={<BsPersonFillAdd />} onClick={() => addContact(event?.contact)} variant={'ghost'} />
              )}
            </Flex>
            <Text fontSize='lg' mt={1}>
              {contactDetailStr}
            </Text>

            <Text
              color={'brand.accent'}
              fontSize='lg'
              fontWeight={'bold'}
              m={0}
              opacity={eventType !== 'general' ? 1 : 0}
              // opacity={eventType === 'history' ? 1 : 0}
              // opacity={eventType === 'history' && !showDetails ? 1 : 0}
              textTransform={'capitalize'}
              // transition={'opacity .5s ease-out'}
            >
              {event.service}
            </Text>
          </Flex>

          <Icon
            as={showDetails ? BiChevronUp : BiChevronDown}
            cursor={'pointer'}
            fontSize={'xl'}
            mb={'auto'}
            position={'absolute'}
            top={2}
            right={2}
            variant={'unstyled'}
            onClick={onDetailsToggle}
          ></Icon>
        </Flex>
        <Box display={'grid'} gridTemplateRows={showDetails ? '1fr' : '0fr'} transition={'all 1s'}>
          <Box overflow={'hidden'}>
            <EventTileDetails event={event} expanded={expandMessage} eventType={eventType} />
          </Box>
        </Box>
        <Flex direction={'row'} alignItems={{ base: 'flex-start', sm: 'flex-end' }} flexWrap={'wrap'} justifyContent={'space-between'} pt={showDetails ? 8 : 0}>
          <Text as={'span'} color={'gray'} fontSize='md' m={0}>
            {event?.createdAt && <span>RCV: {timestampHuman(event.createdAt)}</span>}
          </Text>
          <EventTileActions
            actionState={actionState}
            approveEvent={approveEvent}
            archiveEvent={archiveEvent}
            cancelEvent={cancelEvent}
            deleteEvent={deleteEvent}
            editEvent={editEvent}
            event={event}
            eventType={eventType}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
export default EventTile
