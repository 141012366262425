import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Grid,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react'

import PrimaryButton from '../controls/primary-button'
import SecondaryButton from '../controls/secondary-button'

import { GiHairStrands } from 'react-icons/gi'
import { GiLipstick } from 'react-icons/gi'

//https://media.manuelpellon.com/images/PORTRAITS/manuel.JPG
const PortraitPricingCard = () => {
  const portraitServices = [
    {
      items: [
        'Individual photoshoot',
        'Up to 30 min shoot ',
        '5 high end, retouched photos',
        '1 outfit',
      ],
      price: 250,
      value: 'package-1',
      service: 'Package #1',
    },
    {
      items: [
        'Individual photoshoot',
        'Up to 45 min shoot',
        '7 high end, retouched photos',
        '2 outfits',
      ],
      price: 300,
      value: 'package-2',
      service: 'Package #2',
    },
    {
      items: [
        'Individual photoshoot',
        'Up to 2 hour shoot',
        '10 high end, retouched photos',
        '3 outfits',
      ],
      price: 400,
      value: 'package-3',
      service: 'Package #3',
    },
    {
      items: [
        'Individual photoshoot',
        'Up to 2 hour shoot',
        '15 high end, retouched photos',
        '4 outfits',
      ],
      price: 500,
      value: 'package-4',
      service: 'Package #4',
    },
  ]
  return (
    <Box
      id='real-estate-photography'
      display={'flex'}
      alignContent={{ base: 'center', md: 'flex-start' }}
      alignItems={{ base: 'center', md: 'flex-start' }}
      flexDir={'column'}
      justifyContent={'center'}
      mt={{ base: 0, md: 0 }}
      width={'100%'}
    >
      <Heading
        lineHeight={1.1}
        fontWeight={'bold'}
        fontSize={{ base: '4xl' }}
        my={{ base: 4 }}
        textAlign={'center'}
        textTransform={'uppercase'}
      >
        PORTRAIT PHOTOGRAPHY
      </Heading>
      <Text
        as={'p'}
        fontSize={'2xl'}
        lineHeight={1.2}
        maxWidth={'900px'}
        mb={2}
        textAlign={'left'}
      >
        We work outdoors using natural light, we meet in one specific area in
        NYC and when we find the perfect location we start.
      </Text>
      <Text
        as={'p'}
        color={'brand.accent'}
        fontSize={'2xl'}
        textAlign={'left'}
        mb={2}
        width={'100%'}
      >
        We will help you to capture the best of you in this incredible city.
      </Text>

      <Image
        src={
          'https://manuelpellonphotography.imgix.net/2cb5ae32d363a91e2ee8b21d1fde19e8.jpg'
        }
        // bgAttachment={{ base: 'fixed' }}
        bgPos={{ base: 'center center' }}
        bgRepeat={'no-repeat'}
        height={{ base: 'auto', md: 500, xl: 550 }}
        mt={8}
        objectFit={'cover'}
        width='100%'

        // width={'100vw'}
        // position={'relative'}
        // left={'50%'}
        // right={'50%'}
        // marginLeft={'-50vw'}
        // marginRight={'-50vw'}
      ></Image>
      <Stack
        direction={'row'}
        align={'center'}
        fontSize={30}
        justify={{
          base: 'space-around',
        }}
        py={14}
        w={'100%'}
      >
        <PrimaryButton
          size={'xl'}
          to={'/contact/?service=portrait'}
        ></PrimaryButton>
        <SecondaryButton
          size={'xl'}
          to={'/portfolio/portrait'}
        ></SecondaryButton>
      </Stack>
      {/* TEMP COMMENT FOR PRICING HIDE */}
      {/* UNCOMMENT WHEN REVERTING */}
      <Grid
        justifyItems={'space-between'}
        templateColumns={{
          base: '1fr',
          sm: '2fr 2fr',
          lg: 'repeat(4, 1fr)',
        }}
        gap={4}
        mt={8}
        width={'100%'}
      >
        {portraitServices.map((i) => (
          <Link
            as={RouterLink}
            key={i.service}
            to={`/contact/?service=portrait&package=${i.value}`}
            _hover={{ textDecoration: 'none' }}
          >
            <Box
              border={'2px solid'}
              whiteSpace={'normal'}
              className='package-card'
              transition={'all .5s'}
              _hover={{
                borderColor: 'orange',
                cursor: 'pointer',
                transform: 'scale(1.01)',
              }}
            >
              <Box
                // backgroundColor={'#edf2f7'}
                display={'flex'}
                flexDirection={'column'}
                alignContent={'center'}
                alignItems={{ base: 'center' }}
                fontSize={30}
                gap={2}
                p={4}
                justifyContent={'center'}
                whiteSpace={'nowrap'}

                // fontSize={{ base: '90%', sm: '100%' }}
              >
                <Text
                  as={'h3'}
                  fontWeight={'bold'}
                  sx={{ '.package-card:hover &': { color: 'brand.accent' } }}
                  textDecoration={'underline'}
                  textDecorationColor={'brand.accent'}
                  textDecorationThickness={'2px'}
                  textTransform={'uppercase'}
                  transition={'all .5s'}
                >
                  {i.service}
                </Text>
                <Text as={'h4'} fontWeight={'medium'} my={4}>
                  ${i.price}
                </Text>
                <UnorderedList
                  display={'flex'}
                  flexDirection={'column'}
                  alignContent={'baseline'}
                  alignItems={{ base: 'flex-start' }}
                  gap={4}
                  p={4}
                  width={'fit-content'}
                  justifyContent={'space-between'}
                  flexGrow={1}
                >
                  {i.items.map((item, idx) => (
                    <ListItem
                      key={item}
                      fontSize={'lg'}
                      sx={{ '&::marker': { color: 'brand.accent' } }}
                    >
                      <Text>{item}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </Box>
          </Link>
        ))}
      </Grid>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignContent={'flex-start'}
        alignItems={'flex-start'}
        gap={4}
        height={'100%'}
        justifyContent={'flex-start'}
        flexGrow={1}
        textAlign={'left'}
        mt={12}
        w={'100%'}
      >
        <Text as={'p'} fontSize={30} pb={6}>
          AVAILABLE UPON REQUEST
        </Text>
        <List fontSize={20} spacing={4}>
          <ListItem>
            <ListIcon as={GiHairStrands} color='brand.accent' />
            Hair Stylist
          </ListItem>
          <ListItem>
            <ListIcon as={GiLipstick} color='brand.accent' />
            Makeup artist
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

export default PortraitPricingCard
