import { useEffect, useRef, useState } from 'react'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  inputError,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
// import MultiSelect from '../multi-select'
import TagMain from '../tag-main'

// icons
import { BiLayerPlus } from 'react-icons/bi'
import { MdAddBox, MdClear } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'

// style
import './style.css'

//services
import { fetchImagesByTag } from '../../services/image'
import { addTag, deleteTag, updateTag } from '../../services/tag'

const SaveBtn = ({ isDisabled = false, isLoading = false, onClick }) => {
  return (
    <IconButton
      fontSize={'30px'}
      variant={'unstyled'}
      icon={<MdAddBox />}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      mt={1}
      _hover={{ color: 'brand.accent' }}
    ></IconButton>
  )
}

const TagManagement = ({ onUpdate, tags }) => {
  const initialFocusRef = useRef()
  const [editTagFeedback, setEditTagFeedback] = useState({})
  const [feedback, setFeedback] = useState({})
  const [initialLabel, setInitialLabel] = useState({})
  const [loadingState, setLoadingState] = useState('')
  const [newTagInput, setNewTagInput] = useState('')
  const [newTagIsLoading, setNewTagIsLoading] = useState('')
  const [newTagInputError, setNewTagInputError] = useState(null)
  const [tagDeleting, setTagDeleting] = useState({})
  const [tagEditing, setTagEditing] = useState({})

  // const { isOpen: tagModalOpen, onOpen: onTagModalOpen, onClose: onTagModalClose } = useDisclosure()
  // const tagModalCancelRef = useRef()

  const clearNewTagInput = () => {
    setNewTagInput('')
  }

  const onChange = tag => {
    console.log('temp onChange handler ')
  }

  const onConfirmDeleteTag = async () => {
    // console.log('temp onConfirmDeleteTag handler ')
    setEditTagFeedback({})
    setLoadingState('deleting')

    await deleteTag(tagDeleting?.id)
      .then(res => {
        // console.log(res)
        if (res?.error) {
          throw new Error(res.error)
        }
        //api returns 1 if success
        if (res == '1') {
          // console.log('remove tag')
          // console.log('on success()')
          onUpdate(tags.filter(t => t.id !== tagDeleting.id))
          setFeedback({})
          setFeedback({ status: 'success', message: 'Tag deleted' })
          onModalEditClose()
        }
      })
      .catch(e => {
        console.log(e?.message || 'error adding tag from tag management')
        setEditTagFeedback({ status: 'error', message: e?.message || 'error adding tag' })
      })
      .finally(() => {
        setTagDeleting({})
        setLoadingState('')
      })
  }

  const onDeleteTag = async () => {
    if (!tagEditing?.id) {
      console.error('no tag found to delete')
    }
    setTagDeleting({ ...tagEditing })
  }

  const onEditTag = async tag => {
    setTagEditing({ ...tag, images: null })
    // console.log('....get images for tag')
    // Test if tag is tied to a gallery!

    const images = await fetchImagesByTag(tag?.id)
    setTagEditing({ ...tag, images: images || [] })
  }

  const onModalEditClose = () => {
    // console.log('closing modal')
    setTagDeleting({})
    setEditTagFeedback({})
    setLoadingState('')
    setTagEditing({})
  }

  const onSaveTag = async () => {
    // console.log(tagEditing)
    await updateTag(tagEditing)
      .then(res => {
        // console.log(res)
        if (res?.error) {
          throw new Error(res.error)
        }
        //api returns new tag data if success
        if (res?.id) {
          const newTags = [...tags.filter(t => t.id !== tagEditing.id), { ...res, isUpdated: true }]
          onUpdate(newTags)
          setFeedback({})
          setFeedback({ status: 'success', message: 'Tag updated' })
          onModalEditClose()
        }
      })
      .catch(e => {
        console.log(e?.message || 'error adding tag from tag management')
        setNewTagInputError(e?.message || 'error adding tag')
      })
      .finally(() => {
        setNewTagIsLoading(false)
      })
  }

  const onSaveNewTag = async e => {
    if (e) {
      e.preventDefault()
    }

    if (newTagInput?.length < 2) {
      setNewTagInputError('Tag must be 2 or more characters')
      return
    }

    // validate on backend
    // const tagValue = newTagInput.toLowerCase().replace(/\s/g, '-')
    // // const tagValue = newTagInput.toLowerCase().replaceAll(' ', '-')
    // const tagMatch = tags.findIndex(tag => tag.slug === tagValue)
    // if (tagMatch > -1) {
    //   setNewTagInputError('Duplicate tag found')
    //   return
    // }

    setNewTagIsLoading(true)

    await addTag(newTagInput)
      .then(res => {
        // console.log(res)
        if (res?.error) {
          throw new Error(res.error)
        }
        //api returns new tag data if success
        if (res?.id) {
          onUpdate([...tags, { ...res, isUpdated: true }])
          setNewTagInput('')
        }
      })
      .catch(e => {
        console.log(e?.message || 'error adding tag from tag management')
        setNewTagInputError(e?.message || 'error adding tag')
      })
      .finally(() => {
        setNewTagIsLoading(false)
      })
  }

  useEffect(() => {
    setNewTagInputError('')
  }, [newTagInput])

  useEffect(() => {
    setInitialLabel(tagEditing?.label || '')
  }, [tagEditing?.id])
  return (
    <Box>
      <Box as={'form'} display={'flex'} alignContent={'center'} alignItems={'center'} gap={2} onSubmit={onSaveNewTag}>
        <FormControl isInvalid={!!newTagInputError}>
          <FormLabel fontWeight={'bold'}>Add New</FormLabel>
          <InputGroup>
            <Input autoFocus borderRadius={'none'} ref={initialFocusRef} type={'text'} value={newTagInput} onInput={e => setNewTagInput(e.target.value)} />
            <InputRightElement
              children={newTagInput.length > 1 && <IconButton icon={<MdClear />} cursor={'pointer'} size={'xs'} variant={'unstyled'} onClick={clearNewTagInput}></IconButton>}
            />
          </InputGroup>
          {newTagInputError ? <FormErrorMessage>{newTagInputError}</FormErrorMessage> : <FormHelperText> &nbsp;</FormHelperText>}
        </FormControl>
        <SaveBtn
          // isDisabled={saveDisabled}
          isLoading={newTagIsLoading}
          onClick={onSaveNewTag}
        ></SaveBtn>
      </Box>
      <Heading as={'h6'} size={'md'} mt={8}>
        Saved tags
      </Heading>
      <Box border={'.2px black solid'} display='flex' flexWrap={'wrap'} gap={3} p={4} mt={2}>
        {!tags?.length
          ? 'No tags found'
          : tags
              .sort((a, b) => a.label.localeCompare(b.label))
              .map(tag => {
                return (
                  <TagMain key={tag.slug} className={tag?.isUpdated && 'blink'} label={tag?.label} onClick={() => onEditTag({ ...tag })}>
                    {' '}
                  </TagMain>
                )
              })}
      </Box>
      <Box display={'flex'} minHeight={'80px'} width={'100%'}>
        {feedback?.status && (
          <Alert cursor={'pointer'} status={feedback?.status || 'info'} my={'auto'} className={'fade-in'} onClick={() => setFeedback({})}>
            <AlertIcon />
            <AlertDescription>{feedback?.message || ''}</AlertDescription>
            <CloseButton ml={'auto'} />
          </Alert>
        )}
      </Box>
      {/* EDIT TAG DIALOG */}
      <AlertDialog isCentered isOpen={tagEditing?.id} onClose={onModalEditClose}>
        {/* <AlertDialog isCentered isOpen={tagEditing?.id} leastDestructive onClose={onTagModalClose}> */}
        <AlertDialogOverlay>
          <AlertDialogContent maxH={600} maxW={600} width={'100%'}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              EDIT TAG
            </AlertDialogHeader>

            {/* TODO */}
            {/* <CloseButton /> */}
            <AlertDialogBody>
              <Box display={'flex'} flexDir={'column'} alignItems={'start'} gap={2}>
                <FormControl isInvalid={tagEditing?.label?.length < 1}>
                  <InputGroup>
                    <Input
                      autoFocus
                      borderRadius={'none'}
                      placeholder='Unique label. 2 Char minimum'
                      type={'text'}
                      ref={initialFocusRef}
                      required
                      value={tagEditing.label}
                      onInput={e => setTagEditing({ ...tagEditing, label: e.target.value })}
                    />
                    <InputRightElement>
                      {tagEditing?.label?.length > 0 && <IconButton icon={<MdClear />} onClick={() => setTagEditing({ ...tagEditing, label: '' })} />}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Box display={'flex'} alignItems={'center'}>
                  {tagEditing?.images?.length === 1 ? (
                    'There is 1 image associated with this tag.'
                  ) : tagEditing?.images?.length > 0 ? (
                    `There are ${tagEditing?.images?.length || 0} images associated with this tag.`
                  ) : tagEditing?.images?.length == 0 ? (
                    'No associated images found for tag.'
                  ) : (
                    <span>
                      <Spinner size={'sm'} mr={2} /> Getting affected images...
                    </span>
                  )}
                </Box>
                <Box display={'flex'} minHeight={'80px'} width={'100%'}>
                  {editTagFeedback?.status && (
                    <Alert status={editTagFeedback?.status || 'info'} my={'auto'}>
                      <AlertIcon />
                      {editTagFeedback?.message || ''}
                    </Alert>
                  )}
                </Box>
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter display={'flex'} justifyContent={'space-between'}>
              <Button colorScheme={'yellow'} onClick={onModalEditClose} variant='outline' width={'80px'}>
                CANCEL
              </Button>
              <Button colorScheme={'red'} isLoading={tagDeleting?.id} onClick={() => onDeleteTag()} variant='outline' width={'80px'}>
                DELETE
              </Button>
              <Button colorScheme={'green'} isDisabled={tagEditing?.label === initialLabel || tagEditing?.label?.length < 1} onClick={onSaveTag} variant='outline' width={'80px'}>
                SAVE
              </Button>
            </AlertDialogFooter>

            {/* CONFIRM DELETE */}
            <AlertDialog isCentered isOpen={tagDeleting?.id} onClose={() => setTagDeleting({})}>
              <AlertDialogOverlay>
                <AlertDialogContent maxH={600} maxW={600} width={'100%'}>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    CONFIRM DELETE
                  </AlertDialogHeader>

                  {/* TODO */}
                  {/* <CloseButton /> */}
                  <AlertDialogBody>
                    <Box display={'flex'} flexDir={'column'} alignItems={'start'} gap={2}>
                      <Text as={'p'} textColor={'yellow'}>
                        Are you sure you want to delete the following tag?
                      </Text>
                      <Text as={'p'} fontWeight={'bold'} fontSize={'lg'} my={4}>
                        {tagDeleting?.label || 'tag label not found!'}
                      </Text>
                      <Text as={'p'} fontStyle={'italic'}>
                        {tagDeleting?.images?.length > 0 ? `This tag will be removed from ${tagDeleting?.images?.length} images.` : 'No images will be affected by this change.'}
                      </Text>
                    </Box>
                  </AlertDialogBody>
                  <AlertDialogFooter display={'flex'} justifyContent={'space-between'} mt={4}>
                    <Button colorScheme={'yellow'} onClick={() => setTagDeleting({})} variant='outline'>
                      CANCEL
                    </Button>
                    <Button colorScheme={'red'} isLoading={loadingState === 'deleting'} rightIcon={<IoMdTrash />} onClick={onConfirmDeleteTag} variant='outline'>
                      CONFIRM DELETE
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}
export default TagManagement
