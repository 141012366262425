import { useEffect, useState } from 'react'
import { Box, Button, FormControl, FormLabel, Image, Input } from '@chakra-ui/react'

import TagsSelect from '../controls/tags-select'
//if include ?s '' : add ?
const fixSrc = img => `${img?.src}?fit=crop&w=140&max-h=140`
const ImageEditForm = ({ image, deleteImage, editImage, saveImage, status, tagData, uploadImage }) => {
  const [fileExt, setFileExt] = useState('')
  const [imageName, setImageName] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [tags, setTags] = useState([])

  const onSave = () => {
    const data = {
      ...image,
      fileExt,
      name: imageName,
      tags,
    }
    console.log(data)
    saveImage(data)
  }

  // pickup here.
  // fix tag select

  const onTagSelect = value => {
    console.log(value)
    // if (firstLoad) return
    setTags(value)
  }
  const sizeKB = bytes => Math.floor(bytes / 1024)

  useEffect(() => {
    if (!image) return
    console.log(image)
    const { ext, name, tags } = image
    // console.log(tags)
    //handle this on upload.
    //change imageName to name
    // const [imageName, ext] = image.file.name.split('.')
    // const currentTags = image.tags.split(',').map((i) => i.trim())
    setFileExt(ext)
    setImageName(name)
    onTagSelect([...tags])
    // setFirstLoad(false)
  }, [image])

  return (
    <Box id={'modal-dialog'}  alignItems={'center'} display='flex' flexDirection={'column'} gap={4} height={'100%'} justifyContent={'flex-start'}>
      <Image
        alt={image.name}
        height='auto'
        maxHeight='400px'
        objectFit={'contain'}
        flexShrink={'1'}
        width={'auto'}
        src={image.file ? URL.createObjectURL(image.file) : image.src}
      />
      <FormControl>
        <FormLabel>File name</FormLabel>
        <Input borderRadius={'none'}  focusBorderColor={'brand.accent'} isRequired  value={imageName} onInput={e => setImageName(e.target.value)} required type={'text'} />
      </FormControl>
      <TagsSelect onChange={onTagSelect} tagOptions={Object.values(tagData) || []} tags={tags} />
      <Box display={'flex'} justifyContent={'flex-end'} p={4} w={'full'} mt={'auto'}>
        <Button borderRadius={0} ml={'auto'} isDisabled={!imageName} isLoading={status?.status === 'loading'} onClick={onSave} variant={'solid'}>
          SAVE
        </Button>
      </Box>
    </Box>
  )
}
export default ImageEditForm
