import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabIndicator,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react'

import { AiOutlineClose } from 'react-icons/ai'
import { BsPersonLinesFill } from 'react-icons/bs'
import { FaSearch, FaUserEdit } from 'react-icons/fa'
import { MdEventAvailable, MdMail, MdPhone, MdSend } from 'react-icons/md'
import { PiArrowFatLeftFill, PiArrowFatRightFill } from 'react-icons/pi'
import './style.css'

import { fetchContact } from '../../services/contact'

const tabs = ['contacts', 'packages']

const ManageClients = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [showTabs, setShowTabs] = useState(true)
  const [tags, setTags] = useState([])

  const [contacts, setContacts] = useState([])
  const { colorMode } = useColorMode()
  const [searchInput, setSearchInput] = useState('')
  const clearSearch = () => {
    setSearchInput('')
  }

  const navigate = useNavigate()
  const params = useParams()

  const handleTabsChange = tabIdx => {
    console.log(handleTabsChange)
    setActiveTab(tabIdx)
    const view = tabs[tabIdx]
    if (view) {
      navigate(`/manage/clients/${view}`)
    }
  }

  useEffect(() => {
    console.log('fetching contacts...')
    fetchContact().then(r => {
      if (r?.length) {
        setContacts(r)
      } else {
        setContacts([])
      }
    })
  }, [])

  return (
    <Tabs align='start' index={activeTab} isLazy={true} lazyBehavior={'keepMounted'} onChange={handleTabsChange} variant='unstyled'>
      {showTabs && (
        <Box
          bgColor={colorMode === 'dark' ? 'brandDark.background' : 'brand.background'}
          position={'sticky'}
          // after primary tabs top
          top={186}
          zIndex={19}
        >
          <TabList bgColor={`brand.background.${colorMode}`} shadow={'md'}>
            <Tab fontWeight={'bold'} fontSize={'xl'}>
              CONTACTS
            </Tab>
            <Tab fontWeight={'bold'} fontSize={'xl'}>
              PACKAGES
            </Tab>
          </TabList>
          <TabIndicator mt='-1.5px' height='2px' bg='brand.accent' borderRadius='1px' />
        </Box>
      )}

      <TabPanels pt={4}>
        <TabPanel>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents='none'>
              <Icon as={FaSearch} color='gray.300' />
            </InputLeftElement>
            {searchInput && (
              <InputRightElement cursor={'pointer'}>
                <Icon as={AiOutlineClose} color='gray.300' onClick={clearSearch} />
              </InputRightElement>
            )}
            <Input placeholder='Search clients' focusBorderColor={'brand.accent'} onChange={e => setSearchInput(e.target.value)} type={'search'} value={searchInput} />
          </InputGroup>
          <TableContainer>
            <Table sx={{ tableLayout: 'auto', width: '100%' }}>
              <Thead>
                <Tr>
                  <Th width={1}>
                    <Icon aria-label='last communication' color='brand.accent' as={MdMail} fontSize='lg' transition={'all 1s ease'} variant='ghost' />
                  </Th>
                  <Th color={'brand.accent'}>first</Th>
                  <Th color={'brand.accent'}>last</Th>
                  <Th color={'brand.accent'}>email</Th>
                  <Th color={'brand.accent'}>phone</Th>
                  <Th color={'brand.accent'}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!contacts?.length ? (
                  <Tr>
                    <Td textAlign='center' colSpan='6'>
                      NO CONTACTS FOUND
                    </Td>
                  </Tr>
                ) : (
                  contacts.map(contact => (
                    <Tr key={contact.id}>
                      <Td>-</Td>
                      <Td>{contact.first_name}</Td>
                      <Td>{contact.last_name}</Td>
                      <Td>{contact.email}</Td>
                      <Td>{contact.phone}</Td>
                      <Td className='action-td'>
                        {/* TODO: make btns */}
                        {/* <IconButton icon={<FaUserEdit />} cursor={'pointer'} fontSize={'xl'} title={'Edit Contact'} variant={'ghost'} /> */}
                        <IconButton icon={<BsPersonLinesFill />} cursor={'pointer'} fontSize={'xl'} ml={4} title={'Contact Details'} variant={'ghost'} />
                      </Td>
                    </Tr>
                  ))
                )}
                <Tr _hover={{ borderBottom: '2px solid', borderBottomColor: 'brand.accent', borderTop: '2px solid', borderTopColor: 'brand.accent' }}>
                  <Td>
                    <Tooltip label={'last message received Monday, Dec. 25th 2023 04:20 PM'} closeOnClick={false} hasArrow placement={'top'}>
                      <span>
                        <Icon as={PiArrowFatRightFill} />
                      </span>
                    </Tooltip>
                  </Td>
                  <Td>john</Td>
                  <Td>doe</Td>
                  <Td>jdoe@test.com</Td>
                  <Td>867-5309</Td>
                  <Td className='action-td'>
                    {/* TODO: make btns */}
                    {/* <IconButton icon={<FaUserEdit />} cursor={'pointer'} fontSize={'xl'} title={'Edit Contact'} variant={'ghost'} /> */}
                    <IconButton icon={<BsPersonLinesFill />} cursor={'pointer'} fontSize={'xl'} ml={4} title={'Contact Details'} variant={'ghost'} />
                  </Td>
                </Tr>
                <Tr _hover={{ borderBottom: '2px solid', borderBottomColor: 'brand.accent', borderTop: '2px solid', borderTopColor: 'brand.accent' }}>
                  <Td>
                    <Tooltip label={'last message sent Monday, Dec. 31th 2023 11:59 PM'} closeOnClick={false} hasArrow placement={'top'}>
                      <span>
                        <Icon as={PiArrowFatLeftFill} />
                      </span>
                    </Tooltip>
                  </Td>
                  <Td>jane</Td>
                  <Td>brown</Td>
                  <Td>jbrown@test.com</Td>
                  <Td>867-5309</Td>
                  <Td className='action-td'>
                    {/* TODO: make btns */}
                    {/* <IconButton icon={<FaUserEdit />} cursor={'pointer'} fontSize={'xl'} title={'Edit Contact'} variant={'ghost'} /> */}
                    <IconButton icon={<BsPersonLinesFill />} cursor={'pointer'} fontSize={'xl'} ml={4} title={'Contact Details'} variant={'ghost'} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel>Manage client's photo packages</TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default ManageClients
