import { useEffect, useState } from 'react'
import { Select } from 'chakra-react-select'
import { Box, Checkbox, useColorMode } from '@chakra-ui/react'
const MultiSelect = ({ isDisabled = false, isMulti = true, name, placeHolder, onBlur, onChange, options, value, selectComponents = {} }) => {
  const { colorMode } = useColorMode()
  const [checkedState, setCheckedState] = useState([])

  const onCheckboxChange = e => {
    console.log(e.target.checked)
    if (e?.target?.checked) {
      //prefer to clear all results on partial selection
      if (checkedState === 'indeterminate') {
        onChange([])
      } else {
        onChange([...options])
      }
      return
    }

    if (!e?.target?.checked) {
      onChange([])
    }
  }
  useEffect(() => {
    if (!isMulti) return
    if (!value?.length) {
      setCheckedState('')
      return
    }
    if (value?.length < options?.length) {
      setCheckedState('indeterminate')
      return
    }
    setCheckedState('checked')
  }, [checkedState, isMulti, options, value])

  return (
    <Box display={'flex'} alignItems={'center'} width={'100%'}>
      {isMulti && (
        <Checkbox colorScheme='orange' isChecked={checkedState === 'checked'} isIndeterminate={checkedState === 'indeterminate'} onChange={onCheckboxChange} mr={2} title={'w'} />
      )}
      <Select
        blurInputOnSelect={false}
        closeMenuOnSelect={!isMulti}
        components={{ ...selectComponents }}
        focusBorderColor={'brand.accent'}
        onBlur={onBlur}
        chakraStyles={{
          control: base => ({
            ...base,
            borderRadius: 'none',
            focusBorderColor: 'brand.accent',
            _focus: {
              borderColor: 'brand.accent',
            },
          }),
          container: base => ({
            ...base,
            width: '100%',
          }),

          groupHeading: base => ({
            ...base,
            fontSize: 'md',
            textDecoration: 'underline',
            textDecorationColor: 'orange',
            textAlign: 'left',
          }),

          menu: base => ({
            ...base,
            backgroundColor: `brand.background.${colorMode}`,
            zIndex: 1999,
          }),
          menuList: base => ({
            ...base,
            bg: `brand.background.${colorMode}`,
            borderRadius: 'none',
            focusBorderColor: 'brand.accent',
            maxHeight: '180px',
            overflowY: 'auto',
            zIndex: 1999,
            _focus: {
              borderColor: 'brand.accent',
            },
          }),
          multiValue: base => ({
            ...base,
            border: `2px dotted `,
            borderColor: 'brand.accent',
            borderRadius: 'none',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? `brand.background.${colorMode}` : `brand.background.${colorMode}`,
            color: state.isSelected ? 'yellow' : `brand.primary.${colorMode}`,
            _hover: {
              color: 'brand.accent',
            },
          }),
        }}
        hideSelectedOptions={false}
        isClearable={true}
        isDisabled={isDisabled}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeHolder}
        selectedOptionStyle={'orange'}
        selectedOptionColorScheme={'brand.accent'}
        styles={{ menuPortal: base => ({ ...base, zIndex: 1999 }) }}
        useBasicStyles
        value={value}
      />
    </Box>
  )
}

export default MultiSelect
