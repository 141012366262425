const BASE_URL = process.env.REACT_APP_API_URL

const addContact = async contactLabel => {
  const URL = BASE_URL + 'manage/contact'
  // const formData = new FormData()
  // formData.append('label', contactLabel)
  try {
    const resp = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify({ label: contactLabel }),
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // body: formData,
    })
    console.log(resp)
    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error adding new contact')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught posting contact data===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

const deleteContact = async (contact = '') => {
  const URL = BASE_URL + 'manage/contact/' + contact
  // const formData = new FormData()
  // formData.append('label', contactLabel)
  try {
    const resp = await fetch(URL, {
      method: 'DELETE',
    })
    console.log(resp)
    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error deleting contact')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught deleting contact===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

const fetchContact = async (contact = null) => {
  const URL = BASE_URL + 'manage/contact'
  if (contact) URL += `/${contact}`
  try {
    const resp = await fetch(URL)
    // const data = await resp.json()
    // console.log(data)
    return await resp.json()
    // return data
  } catch (e) {
    console.log('error caught fetching contact data')
    console.log(e)
  }
}

const updateContact = async contactData => {
  const URL = BASE_URL + 'manage/contact'
  // const formData = new FormData()
  // formData.append('label', contactLabel)
  try {
    const resp = await fetch(URL, {
      method: 'PUT',
      body: JSON.stringify(contactData),
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // body: formData,
    })
    console.log(resp)
    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error updating contact')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught posting contact data===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

module.exports = {
  addContact,
  deleteContact,
  fetchContact,
  updateContact,
}
