const BASE_URL = process.env.REACT_APP_API_URL

const addTag = async tagLabel => {
  const URL = BASE_URL + 'manage/tag'
  // const formData = new FormData()
  // formData.append('label', tagLabel)
  try {
    const resp = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify({ label: tagLabel }),
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // body: formData,
    })
    console.log(resp)
    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error adding new tag')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught posting tag data===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

const deleteTag = async (tag = '') => {
  const URL = BASE_URL + 'manage/tag/' + tag
  // const formData = new FormData()
  // formData.append('label', tagLabel)
  try {
    const resp = await fetch(URL, {
      method: 'DELETE',
    })
    console.log(resp)
    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error deleting tag')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught deleting tag===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

const fetchTag = async (tag = null, byId = false) => {
  const URL = BASE_URL + 'tag'
  if (tag) URL += `/${tag}`
  try {
    const resp = await fetch(URL)
    const data = await resp.json()

    if (byId) {
      if (data?.length) {
        const tagsById =
          data?.reduce((acc, curr) => {
            return { ...acc, [curr.id.toString()]: { ...curr } }
          }, {}) || {}
        return tagsById
      }
      if (data?.id) {
        return { [data.id]: data }
      }
    }
    return data
  } catch (e) {
    console.log('error caught fetching tag data')
    console.log(e)
  }
}

const fetchGalleryTagData = async () => {
  const URL = BASE_URL + 'tag/gallery'
  console.log(URL)
  // const url = BASE_URL + category
  try {
    const resp = await fetch(URL)
    // const data = await resp.json()
    // console.log(data)
    return await resp.json()
    // return data
  } catch (e) {
    console.log('error caught fetching tag data')
    console.log(e)
    throw new Error(e)
  }
}

const updateTag = async tagData => {
  const URL = BASE_URL + 'manage/tag'
  // const formData = new FormData()
  // formData.append('label', tagLabel)
  try {
    const resp = await fetch(URL, {
      method: 'PUT',
      body: JSON.stringify(tagData),
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // body: formData,
    })
    console.log(resp)
    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error updating tag')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught posting tag data===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

module.exports = {
  addTag,
  deleteTag,
  fetchTag,
  fetchGalleryTagData,
  updateTag,
}
