import { useEffect, useRef, useState } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  inputError,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Tag,
  TagCloseButton,
  TagLabel,
  useDisclosure,
} from '@chakra-ui/react'
import MultiSelect from '../multi-select'

import { BiLayerPlus } from 'react-icons/bi'
import { MdAddBox, MdClear } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import './style.css'
// const ClearBtn = ({ isDisabled, onClick }) => {
//   return (
//     <IconButton
//       as={MdClear}
//       cursor={'pointer'}
//       size={'xs'}
//       variant={'unstyled'}
//       onClick={onClick}
//     ></IconButton>
//   )
// }

const SaveBtn = ({ isDisabled = false, onClick }) => {
  return <IconButton icon={<MdAddBox />} disabled={isDisabled} fontSize={'30px'} variant={'unstyled'} onClick={onClick}></IconButton>
}

const TagsSelect = ({ helperText, isDisabled, label, onAvailableTagsUpdated, onBlur, onChange, placeHolder, tagOptions, tags }) => {
  const initialFocusRef = useRef()
  const [newTagInput, setNewTagInput] = useState('')
  // const [tagInput, setTagInput] = useState(tags)
  const [newTagInputError, setNewTagInputError] = useState(null)
  const { isOpen: tagModalOpen, onOpen: onTagModalOpen, onClose: onTagModalClose } = useDisclosure()
  const tagModalCancelRef = useRef()

  // const clearInput = () => {
  //   setTagInput('')
  // }

  // const clearNewTagInput = () => {
  //   setNewTagInput('')
  // }

  const handleTagInput = (input, action) => {
    console.log(input, action)
    const tags = action?.action === 'clear' ? [] : input.sort((a, b) => a.label.localeCompare(b.label))
    // setTagInput(tags)
    onChange(tags)
  }

  const onDeleteTag = tag => {
    console.log(tag)
    const alteredTags = [...tagOptions].filter(t => t.value !== tag.value)
    console.log(alteredTags)
    onAvailableTagsUpdated(alteredTags)
  }

  // const onSaveNewTag = () => {
  //   if (newTagInput?.length < 2) {
  //     setNewTagInputError('Tag must be 2 or more characters')
  //     return
  //   }
  //   const tagValue = newTagInput.toLowerCase().replaceAll(' ', '-')
  //   const tagMatch = tagOptions.findIndex(tag => tag.value === tagValue)
  //   if (tagMatch > -1) {
  //     setNewTagInputError('Duplicate tag found')
  //     return
  //   }
  //   console.log('update api here')
  //   //TODO:
  //   //create add tag, remove tag props
  //   const alteredTags = [...tagOptions, { label: newTagInput, value: tagValue }]
  //   console.log(alteredTags)
  //   onAvailableTagsUpdated(alteredTags)

  //   // onTagsChange()
  // }

  // useEffect(() => {
  //   setTagInput(tags)
  // }, [tags])

  return (
    <FormControl>
      <FormLabel>{label || 'Tags'}</FormLabel>
      <Box alignItems={'center'} display={'flex'}>
        {/* <Select
          closeMenuOnSelect={false}
          value={tags}
          focusBorderColor={'brand.accent'}
          chakraStyles={{
            container: base => ({
              flexGrow: 1,
            }),
            control: base => ({
              ...base,
              borderRadius: 'none',
              focusBorderColor: 'brand.accent',
              width: '100%',
              _focus: {
                borderColor: 'brand.accent',
              },
            }),
            multiValue: base => ({
              ...base,
              border: `2px dotted brand.accent`,
              borderRadius: 'none',
            }),
            option: (base, state) => ({
              ...base,
              background: state.isFocused ? 'brand.disabled' : 'unset',
              color: state.isSelected ? 'brand.accent' : base.background,
              _hover: {
                color: state.isSelected ? 'brand.accent' : base.background,
              },
            }),
          }}
          // defaultValue={[serviceOptions[2]]}
          hideSelectedOptions={true}
          isClearable={true}
          isMulti
          options={tagOptions}
          onChange={onChange}
          variant={'unstyled'}
        /> */}
        <FormControl>
          <InputGroup display={'flex'} alignItems={'center'}>
            {/* <Icon as={ImCamera} mr={2} boxSize={'20px'} /> */}
            <MultiSelect
              isDisabled={isDisabled}
              isMulti={true}
              name={'tag-select'}
              onBlur={onBlur}
              options={Object.values(tagOptions).sort((a, b) => a.slug?.localeCompare(b.slug)) || []}
              onChange={handleTagInput}
              placeHolder={placeHolder || 'Select tags'}
              value={tags}
            />
          </InputGroup>
          <FormHelperText fontSize={16}>{helperText}</FormHelperText>
        </FormControl>
      </Box>
    </FormControl>
  )
}
export default TagsSelect
