import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabIndicator,
  TabPanel,
  useColorMode,
  // useDisclosure,
} from '@chakra-ui/react'

// import ImageEditForm from '../../components/image-edit-form'
import ManageImageGallery from '../../components/manage-image-gallery'
import ManageImageLibrary from '../../components/manage-image-library'
// import ModalDialog from '../../components/controls/modal-dialog'

import { createSrc, createSrcSet } from '../../services/image'

const tabs = ['gallery', 'library']

const ManageImages = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { colorMode } = useColorMode()
  const [images, setImages] = useState([])

  const [showTabs, setShowTabs] = useState(true)

  //delay loading of tab list on screen due to bug possibly rendering before font load
  // setTimeout(() => setShowTabs(true), 800)

  //modal
  // const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

  //url/nav
  const navigate = useNavigate()
  const params = useParams()

  const handleTabsChange = (tabIdx) => {
    setActiveTab(tabIdx)
    const view = tabs[tabIdx]
    if (view) {
      navigate(`/manage/images/${view}`)
    }
  }

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const URL = process.env.REACT_APP_API_URL + 'image'
        const resp = await fetch(URL)
        const data = await resp.json()
        // console.log(data)
        if (data && data?.length > 0) {
          const images = data.map((i) => ({
            ...i,
            src: createSrc(i.file_name),
            srcSet: createSrcSet(i),
          }))
          setImages(images)
        }
      } catch (e) {
        console.log('error caught fetching image list')
        console.log(e)
      }
    }
    fetchPageData()
  }, [])

  useEffect(() => {
    //tabs set to lazy and keep mounted.
    // Only setup if on images view and active tab is not initialized
    if (params?.view !== 'images') return
    if (activeTab) return
    if (params?.secondaryView) {
      const idx = tabs.indexOf(params.secondaryView)
      if (idx > -1) {
        setActiveTab(idx)
      } else {
        handleTabsChange(0)
      }
    } else {
      handleTabsChange(0)
    }
  }, [params])

  return (
    <>
      <Tabs
        align='start'
        index={activeTab}
        isLazy={true}
        lazyBehavior={'keepMounted'}
        onChange={handleTabsChange}
        variant='unstyled'
      >
        {showTabs && (
          <Box
            bgColor={
              colorMode === 'dark' ? 'brandDark.background' : 'brand.background'
            }
            position={'sticky'}
            // after primary tabs top
            top={186}
            zIndex={19}
          >
            <TabList bgColor={`brand.background.${colorMode}`} shadow={'md'}>
              <Tab fontWeight={'bold'} fontSize={'xl'}>
                GALLERY
              </Tab>
              <Tab fontWeight={'bold'} fontSize={'xl'}>
                LIBRARY
              </Tab>
            </TabList>
            <TabIndicator
              mt='-1.5px'
              height='2px'
              bg='brand.accent'
              borderRadius='1px'
            />
          </Box>
        )}

        <TabPanels pt={4}>
          <TabPanel>
            <ManageImageGallery images={images} />
          </TabPanel>
          <TabPanel>
            <ManageImageLibrary images={images} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <ModalDialog
        isOpen={isModalOpen}
        onOpen={onModalOpen}
        onClose={onModalClose}
        modalBody={<ImageEditForm image={uploadEditing} saveImage={onSaveUploadImage} tagOptions={tagOptions} />}
        title={'EDIT IMAGE'}
      /> */}
    </>
  )
}

export default ManageImages
