import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  extendTheme,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import { chakraComponents } from 'chakra-react-select'
import CaptchaWidget from '../controls/captcha-widget'
import { DatePicker } from 'chakra-datetime-picker'
import MultiSelect from '../controls/multi-select'

import {
  BiBuildingHouse,
  BiCalendarEvent,
  BiSolidBuildingHouse,
} from 'react-icons/bi'
import {
  BsFillPersonFill,
  BsListStars,
  BsPersonBoundingBox,
} from 'react-icons/bs'
import { FaAddressBook } from 'react-icons/fa6'
import { ImCamera } from 'react-icons/im'
import { IoGrid, IoLocationSharp } from 'react-icons/io5'
// import { LuCalendarClock } from 'react-icons/lu'
import { MdEventAvailable, MdMail, MdPhone, MdSend } from 'react-icons/md'
import { TfiLayoutGrid2Alt } from 'react-icons/tfi'
import AlertWrapper from '../alert-wrapper'
import {
  portraitOptions,
  residentialOptions,
  serviceOptions,
  virtualStagingOptions,
} from '../../data/contactOptions'
import usStates from '../../data/usStates'

//Custom props for multiselect components

const customSelectComponents = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Box
        color={props.isSelected && 'brand.accent'}
        display={'flex'}
        justifyContent={'space-between'}
        w={'100%'}
      >
        {/* <p> {JSON.stringify(props.isSelected)}</p> */}
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyContent={'space-between'}
          w={'100%'}
        >
          <Text fontWeight={'bold'}> {props?.data?.label}</Text>
          {props?.data?.description ?? (
            <Text fontSize={1}>{props?.data?.description}</Text>
          )}
        </Box>
        <Text> {props?.data?.costLocal ?? ''}</Text>
      </Box>
    </chakraComponents.Option>
  ),
}

const ContactForm = () => {
  // TODO:
  //REFACTOR WHOLE COMPONENT,
  //CREATE FORM OBJECT

  const pageLocation = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  //input fields
  const [location, setLocation] = useState({
    address: '',
    city: '',
    state: { label: 'New York', value: 'NY' },
    zipcode: '',
  })
  // const [addressInput, setAddressInput] = useState('')
  const [captchaSolution, setCaptchaSolution] = useState('')
  // const [cityInput, setCityInput] = useState('')
  const [dateInput, setDateInput] = useState('')
  const [timeInput, setTimeInput] = useState('')
  const [emailInput, setEmailInput] = useState('')
  const [messageInput, setMessageInput] = useState('')
  const [firstNameInput, setFirstNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [phoneInput, setPhoneInput] = useState('')
  // const [zipcodeInput, setZipcodeInput] = useState('')

  //select fields
  const [selectedService, setSelectedService] = useState([])
  const [selectedPortraitPackage, setSelectedPortraitPackage] = useState('')
  const [selectedPortraitOptions, setSelectedPortraitOptions] = useState([])
  const [selectNumResidentialItems, setSelectNumResidentialItems] = useState([])
  const [selectedNumResidential, setSelectedNumResidential] = useState('')
  const [selectedResidentialOptions, setSelectedResidentialOptions] = useState([])
  const [selectedState, setSelectedState] = useState([])
  const [selectedVirtualStaging, setSelectedVirtualStaging] = useState([])

  //form state
  const [invalidFields, setInvalidFields] = useState([])
  // const [showAddressSelect, setShowAddressSelect] = useState(false)
  const [showDateSelect, setShowDateSelect] = useState(false)
  const [showPortraitSelect, setShowPortraitSelect] = useState(false)
  const [showResidentialSelect, setShowResidentialSelect] = useState(false)
  // const [showVirtualStagingSelect, setShowVirtualStagingSelect] =
  // useState(false)
  const [submitResult, setSubmitResult] = useState(null)
  const [submitStatus, setSubmitStatus] = useState(null)

  //Custom props (tobe moved)
  const datepickerProps = {
    colorScheme: 'datetimepicker',
    currentLangKey: 'en',
    disableTimestampBefore: new Date().getTime(),
    showTimeSelector: true,
  }

  const captachaWidget = useRef()
  const contactForm = useRef()
  const nameInputEl = useRef()
  const emailInputEl = useRef()

  const createNumResidentialItems = () => {
    //TODO!! memoize
    //creates number of photos options for real estate

    //min amount of photos, base price 180, +40 each add.
    return new Promise((resolve) => {
      let count = 4
      let cost = 180
      const costAdditionalPhoto = 40
      const items = new Array(99 - count + 1).fill('').map(() => {
        const option = {
          cost,
          costLocal: cost.toLocaleString('en-us', {
            currency: 'USD',
            style: 'currency',
          }),
          label: `${count} photos`,
          value: count.toString(),
        }
        count += 1
        cost += costAdditionalPhoto
        return option
      })
      // console.log(items)
      setSelectNumResidentialItems(items)
      // console.log(selectNumResidentialItems)
      resolve(items) 
    })
  
  }

  const handleDateInput = (e) => {
    setDateInput(e.target.value)
  }

  const handleEmailInput = (e) => {
    setEmailInput(e.target.value)
  }

  const handleFirstNameInput = (e) => {
    setFirstNameInput(e.target.value)
  }

  const handleLastNameInput = (e) => {
    setLastNameInput(e.target.value)
  }

  const handleLocationInput = (e = {}) => {
    setLocation({ ...location, ...e })
  }

  const handleNumResidential = (e) => {
    // console.log(e)
    // console.log(selectedNumResidential)
    // setSelectedNumResidential([e.value])
    setSelectedNumResidential(e)
    // console.log(searchParams)
    // console.log(setSearchParams)
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        photos: e.value,
      })
    })
  }
  const handleMessageInput = (e) => {
    setMessageInput(e.target.value)
  }

  const handlePhoneInput = (e) => {
    setPhoneInput(e.target.value)
  }

  const handlePortraitOptionSelect = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedPortraitOptions([])
      return
    }

    // if (action?.action === 'select-option' && action?.option.isBaseOption) {
    //   input = [...input.filter((i) => !i.isBaseOption), action?.option]
    // }

    input = input.sort((a, b) => a.label.localeCompare(b.label))
    setSelectedPortraitOptions(input)
  }

  const handlePortraitPackageSelect = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedPortraitPackage('')
      return
    }
    setSelectedPortraitPackage(input)

    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        package: input.value,
      })
    })
    // if (action?.action === 'select-option' && action?.option.isBaseOption) {
    //   input = [...input.filter((i) => !i.isBaseOption), action?.option]
    // }

    // input = input.sort((a, b) => a.label.localeCompare(b.label))
  }

  const handleResidentialInput = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedResidentialOptions([])
      return
    }
    if (action?.action === 'select-option' && action?.option.isBaseOption) {
      input = [...input.filter((i) => !i.isBaseOption), action?.option]
    }
    input = input.sort((a, b) => a.label.localeCompare(b.label))
    setSelectedResidentialOptions(input)
  }

  const handleServiceInput = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedService([])
      return
    }
    setSelectedService(input)
    //If changing service by user, clear any search params
    if (action?.action === 'select-option') {
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          service: input.value,
        })
      })
      return
    }
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        service: input.value,
      })
    })
    //if multiselect
    // const inputSorted = input.sort((a, b) => a.label.localeCompare(b.label))
    // setSelectedService(inputSorted)
  }

  const handleStateSelect = (input, action) => {
    handleLocationInput({ state: input })
    // if (action?.action === 'clear') {
    //   setSelectedService([])
    //   return
    // }
    // const inputSorted = input.sort((a, b) => a.label.localeCompare(b.label))
    // setSelectedService(inputSorted)
  }

  const handleSubmitForm = async (e) => {
    console.log(e)
    if (!e) {
    }
    e.preventDefault()
    setSubmitResult(null)

    console.warn('temporarily disabled captcha req')
    // if (!captchaSolution) {
    //   setSubmitResult({
    //     status: 'error',
    //     message: 'Click the button above to verify',
    //     title: 'Please complete captcha',
    //   })
    //   return
    // }
    const formDataStateMap = {
      captcha: captchaSolution,
      req_date: dateInput,
      req_time: timeInput,
      email: emailInput,
      first_name: firstNameInput,
      last_name: lastNameInput,
      message: messageInput,
      phone: phoneInput,
      service: selectedService?.value || '',
    }

    if (formDataStateMap.service === 'portrait') {
      formDataStateMap.portrait_options = selectedPortraitOptions?.length ? selectedPortraitOptions?.map(o => o.value) : null
      formDataStateMap.portrait_package = selectedPortraitPackage?.value || ''

    }
    if (formDataStateMap.service === 'residential') {

      formDataStateMap.residential_options  = selectedResidentialOptions?.map(o => o.value) || null
      formDataStateMap.residential_photos  = selectedNumResidential?.value || ''
      formDataStateMap.location_address  = location?.address || ''
      formDataStateMap.location_city  = location?.city || ''
      formDataStateMap.location_state  = location?.state?.value || ''
      formDataStateMap.location_zipcode  = location?.zipcode || ''
      // formDataStateMap.residential_virtual_staging  = true || ''//todo: find optional selected
      // formDataStateMap.residential_itemized_declutter = true || '' //todo: find optional selecte

    }

      // 'virtual-staging': selectedVirtualStaging.map((s) => s.value),
    console.log(formDataStateMap)
    // return
    setSubmitStatus('loading')
    setSubmitResult(null)
    try {
      // const url = 'http://localhost:3030/contact'
      const url = process.env.REACT_APP_API_URL + 'contact/test'
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(formDataStateMap),
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      const data = await res.json()
      console.log(data)
      // setSubmitStatus(null)
      // return 
      console.log('set timeout to see if request timed out and report.. ')
      setSubmitResult({
        status: data?.status || 'error',
        message: data?.message || 'Error submitting.',
        title: data?.title || null,
      })

      if (data.status === 'success') {
        // resetForm()
        setSubmitResult({ ...data })  
      }
    } catch (e) {
      setSubmitResult({
        status: e?.status || 'error',
        message: e?.message || 'Error encountered while submitting.',
      })
    }
    setSubmitStatus(null)
  }

  const handleSubmitButton = (e) => {
    e.preventDefault()
    setInvalidFields([])
    let errorMessage = 'Please enter a valid '
    const nameIsValid = nameInputEl.current.checkValidity()
    const emailIsValid = emailInputEl.current.checkValidity()
    const withError = []
    if (!nameIsValid || !emailIsValid) {
      if (!nameIsValid) {
        errorMessage += 'name'
        withError.push(nameInputEl)
      }
      if (!emailIsValid) {
        errorMessage += !nameIsValid ? ' and email.' : 'email.'
        withError.push(emailInputEl)
      } else {
        errorMessage += '.'
      }
      setInvalidFields(withError)
      setSubmitResult({
        status: 'error',
        message: errorMessage,
      })

      return
    }
    handleSubmitForm(e)
  }

  const handleTimeInput = (e) => {
    setTimeInput(e.target.value)
  }

  const handleVirtualStagingInput = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedVirtualStaging([])
      return
    }
    setSelectedVirtualStaging(input)
  }

  const onCaptchaDone = useCallback((solution) => {
    setCaptchaSolution(solution)
  }, [])

  const resetForm = () => {
    setCaptchaSolution('')
    setDateInput('')
    setEmailInput('')
    setMessageInput('')
    setFirstNameInput('')
    setLastNameInput('')
    setPhoneInput('')
    setSelectedService([])
    setSelectedPortraitPackage([])
    setSelectedResidentialOptions([])
    setSelectedVirtualStaging([])
    setShowDateSelect(false)
    setShowPortraitSelect(false)
    setShowResidentialSelect(false)
    // setShowVirtualStagingSelect(false)
    setSubmitResult(null)
    setSubmitStatus(null)
    captachaWidget?.current?.reset()
  }

  // useEffect(() => {
  //   console.log(pageLocation)

  //   // const offset = 120 //clear navbar
  //   const offset = 0 //clear navbar
  //   const timoutID = setTimeout(() => {
  //     window.scrollTo({
  //       behavior: 'smooth',
  //       top:
  //         contactForm.current.getBoundingClientRect().top -
  //         document.body.getBoundingClientRect().top -
  //         offset,
  //     })
  //   }, 500)
  //   return () => clearTimeout(timoutID)
  // }, [pageLocation?.pathname])

  useEffect(() => {
    const service = searchParams.get('service')
    const serviceOption = serviceOptions.find(
      (s) => s.value.toLowerCase() === service?.toLowerCase(),
    )
    if (serviceOption) {
      handleServiceInput(serviceOption)
      if (serviceOption.value === 'portrait') {
        const packageOption = portraitOptions.base.find(
          (o) => o.value === searchParams.get('package'),
        )
        if (packageOption) {
          handlePortraitPackageSelect(packageOption, 'select-option')
        }
        // handlePortraitInput()
      }
      if (serviceOption.value === 'residential') {
        const numPhotos = searchParams.get('photos')
        if (numPhotos) {
          // console.log(numPhotos)
          // console.log(selectNumResidentialItems)
          // console.log(numPhotos)
          
          if (!selectNumResidentialItems?.length) {
            createNumResidentialItems().then((items) => {
              const selectValue = items.find(
                (i) => i.value === numPhotos,
              )
              if (selectValue) {
                handleNumResidential(selectValue)
              }
              return
            })
          }
          
      
         
          const selectValue = selectNumResidentialItems.find(
            (i) => i.value === numPhotos,
          )
          if (selectValue) {
            handleNumResidential(selectValue)
          }
        }
      }
    }
    // urlParams,
    // no longer need?
  }, [searchParams])

  useEffect(() => {
    //if multi select
    // const hasResidential = selectedService.find(s => s.value === 'residential') !== undefined

    const hasResidential = selectedService?.value === 'residential'
    setShowResidentialSelect(hasResidential)

    // setShowResidentialSelect(hasResidential)
    if (hasResidential && selectNumResidentialItems?.length < 1) {
      createNumResidentialItems()
    }

    if (selectNumResidentialItems?.length > 0 && !selectedNumResidential) {
      console.log(selectNumResidentialItems)
      setSelectedNumResidential(selectNumResidentialItems?.[0] || '')
    }

    //if multi select
    // const hasPortraitPhotography = selectedService.find(s => s.value === 'portrait') !== undefined

    const hasPortraitPhotography = selectedService?.value === 'portrait'
    setShowPortraitSelect(hasPortraitPhotography)

    // no longer showing..
    // setShowVirtualStagingSelect(
    //   selectedService.find((s) => s.value === 'virtual-staging') !== undefined,
    // )
  }, [searchParams, selectNumResidentialItems, selectedService])

  useEffect(() => {
    setShowDateSelect(showPortraitSelect || showResidentialSelect)
  }, [showPortraitSelect, showResidentialSelect])

  return (
    <Box
      as={'form'}
      bg={'transparent'}
      border={'solid'}
      // borderColor={'brand.primary'}
      borderWidth={1.5}
      borderRadius='none'
      height={'100%'}
      id={'contact-form'}
      minHeight={'600px'}
      name={'contact-form'}
      onSubmit={handleSubmitForm}
      p={{ base: 4, md: 8 }}
      ref={contactForm}
      // color={'brand.primary'}
      shadow='base'
    >
      <Box mb={8}>
        <Text as={'p'} fontSize={'lg'}>
          {/* For Real Estate Photography inquiries, please provide all relevant
          information about the property including location
               <Text as={'span'} fontSize={'sm'} ml={'1.25px'}>
            (s)
          </Text>
          .
         */}
          For general inquiries or appointment requests, please submit the below
          form. <br />
          Selecting any of the "services" will display additional, pertinent
          options. <br />
        </Text>
        <Text as={'p'} fontSize={'xs'}>
          *Listed prices are for reference only. You will not be prompted for a
          payment.
        </Text>
        <Text
          as={'p'}
          color={'brand.accent'}
          fontSize={'lg'}
          fontWeight={'bold'}
          mt={2}
        >
          Thank you and we look forward to working with you!
        </Text>
      </Box>
      <VStack height='100%' spacing={10}>
        {/* NAME INPUT */}
        <FormControl>
          <FormLabel
            display={'flex'}
            alignContent={'center'}
            alignItems={'end'}
            lineHeight={'22px'}
            mb={{ base: 2 }}
          >
            <Icon as={BsFillPersonFill} boxSize={'22px'} mr={1} />

            <Text as={'span'} fontSize={'lg'}>
              Name
            </Text>
          </FormLabel>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            overflow={'hidden'}
            spacing={{ base: 4 }}
          >
            <FormControl
              isRequired
              isInvalid={invalidFields.includes(nameInputEl)}
            >
              <FormLabel
                display={'flex'}
                alignContent={'center'}
                lineHeight={'22px'}
                mb={1}
              >
                <Text as={'span'} fontSize={'sm'}>
                  First
                </Text>
              </FormLabel>
              <Input
                borderRadius={'none'}
                focusBorderColor={'brand.accent'}
                name='name'
                onChange={handleFirstNameInput}
                onFocus={() =>
                  setInvalidFields(
                    invalidFields.filter((i) => i !== nameInputEl),
                  )
                }
                // placeholder='Full Name*'
                ref={nameInputEl}
                type='text'
                value={firstNameInput}
              />
              {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
            </FormControl>
            <FormControl
              isRequired
              isInvalid={invalidFields.includes(nameInputEl)}
            >
              <FormLabel
                display={'flex'}
                alignContent={'center'}
                lineHeight={'22px'}
                mb={1}
              >
                <Text as={'span'} fontSize={'sm'}>
                  Last
                </Text>
              </FormLabel>
              <Input
                borderRadius={'none'}
                focusBorderColor={'brand.accent'}
                name='name'
                onChange={handleLastNameInput}
                onFocus={() =>
                  setInvalidFields(
                    invalidFields.filter((i) => i !== nameInputEl),
                  )
                }
                // placeholder='Full Name*'
                ref={nameInputEl}
                type='text'
                value={lastNameInput}
              />
              {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
            </FormControl>
          </SimpleGrid>
        </FormControl>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          overflow={'hidden'}
          spacing={{ base: 4 }}
          width={'100%'}
        >
          {/* EMAIL INPUT */}

          <FormControl
            isRequired
            isInvalid={invalidFields.includes(emailInputEl)}
          >
            {/* <p>TODO: group these into "contact"?</p>
          <Icon as={FaAddressBook} boxSize={'22px'} mr={1} /> */}
            <FormLabel
              display={'flex'}
              alignContent={'center'}
              alignItems={'end'}
              lineHeight={'22px'}
              mb={1}
            >
              <Icon as={MdMail} boxSize={'22px'} mr={1} />

              <Text as={'span'} fontSize={'lg'}>
                Email Address
              </Text>
            </FormLabel>

            <InputGroup display={'flex'} alignItems={'center'}>
              {/* <Icon as={MdMail} mr={2} boxSize={'20px'} /> */}
              <Input
                borderRadius={'none'}
                focusBorderColor={'brand.accent'}
                name='email'
                onChange={handleEmailInput}
                onFocus={() =>
                  setInvalidFields(
                    invalidFields.filter((i) => i !== emailInputEl),
                  )
                }
                // placeholder='Email*'
                ref={emailInputEl}
                type='email'
                value={emailInput}
              />
            </InputGroup>
            {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
          </FormControl>

          {/* PHONE INPUT */}

          <FormControl>
            {/* <FormLabel>Phone</FormLabel> */}
            <FormLabel
              display={'flex'}
              alignContent={'center'}
              alignItems={'end'}
              lineHeight={'22px'}
              mb={1}
              mt={{ base: 4, md: 0 }}
            >
              <Icon as={MdPhone} boxSize={'22px'} mr={1} />
              <Text as={'span'} fontSize={'lg'}>
                Telephone Number
              </Text>
            </FormLabel>
            <InputGroup display={'flex'} alignItems={'center'}>
              {/* <Icon as={MdPhone} mr={2} boxSize={'20px'} /> */}
              {/* <InputLeftElement children={<MdPhone />} /> */}
              <Input
                borderRadius={'none'}
                focusBorderColor={'brand.accent'}
                name='phone'
                onChange={handlePhoneInput}
                // placeholder='Phone number'
                type='tel'
                value={phoneInput}
              />
            </InputGroup>
          </FormControl>
        </SimpleGrid>
        <FormControl>
          {/* <FormLabel>Services</FormLabel> */}
          <FormLabel
            display={'flex'}
            alignContent={'center'}
            alignItems={'end'}
            lineHeight={'22px'}
            mb={1}
          >
            <Icon as={IoGrid} boxSize={'20px'} mr={1} />

            <Text as={'span'} fontSize={'lg'}>
              Services
            </Text>
          </FormLabel>

          <InputGroup display={'flex'} alignItems={'center'}>
            {/* <Icon as={ImCamera} mr={2} boxSize={'20px'} /> */}
            <MultiSelect
              isMulti={false}
              name={'services-select'}
              options={serviceOptions}
              onChange={handleServiceInput}
              // placeHolder={'Services'}
              selectComponents={customSelectComponents}
              value={selectedService}
            />
          </InputGroup>
        </FormControl>

        {showPortraitSelect && (
          <FormControl>
            <FormLabel
              display={'flex'}
              alignContent={'center'}
              alignItems={'end'}
              lineHeight={'22px'}
              mb={{ base: 4 }}
            >
              <Icon as={BsPersonBoundingBox} boxSize={'20px'} mr={1} />

              <Text as={'span'} fontSize={'lg'}>
                Portrait Options
              </Text>
            </FormLabel>
            <InputGroup
              display={'flex'}
              alignItems={'flex-start'}
              flexDir={{ base: 'column', md: 'row', xl: 'column' }}
              flexWrap={'1'}
            >
              <FormControl
                maxW={{ base: '100%', md: '30%', xl: '100%' }}
                pr={{ base: 0, md: 4, xl: 0 }}
              >
                <FormLabel
                  display={'flex'}
                  alignContent={'center'}
                  lineHeight={'22px'}
                  mb={1}
                >
                  <Text as={'span'} fontSize={'sm'}>
                    Package
                  </Text>
                </FormLabel>
                <MultiSelect
                  isMulti={false}
                  name={'portrait-package-select'}
                  options={portraitOptions.base}
                  onChange={handlePortraitPackageSelect}
                  selectComponents={customSelectComponents}
                  value={selectedPortraitPackage}
                />
              </FormControl>
              <FormControl mt={{ base: 4, md: 0, xl: 4 }}>
                {/* <FormLabel>Services</FormLabel> */}

                <FormLabel
                  display={'flex'}
                  alignContent={'center'}
                  alignItems={'end'}
                  lineHeight={'22px'}
                  mb={1}
                >
                  <Text as={'span'} fontSize={'sm'}>
                    Additional Options
                  </Text>
                </FormLabel>
                <MultiSelect
                  name={'portrait-select'}
                  options={portraitOptions.extra}
                  onChange={handlePortraitOptionSelect}
                  placeHolder={'Select...'}
                  selectComponents={customSelectComponents}
                  // placeHolder={'Portrait Options'}
                  value={selectedPortraitOptions}
                />
              </FormControl>
            </InputGroup>
            <InputGroup display={'flex'} alignItems={'center'}>
              {/* <Icon as={BsListStars} mr={2} boxSize={'20px'} /> */}
              {/* <InputLeftElement children={<MdPhone />} /> */}
            </InputGroup>
          </FormControl>
        )}
        {showResidentialSelect && (
          <>
            <FormControl>
              {/* <FormLabel>Services</FormLabel> */}

              <FormLabel
                display={'flex'}
                alignContent={'center'}
                alignItems={'end'}
                lineHeight={'22px'}
                mb={{ base: 4 }}
              >
                <Icon as={BiSolidBuildingHouse} boxSize={'22px'} mr={1} />

                <Text as={'span'} fontSize={'lg'}>
                  Real Estate Options
                </Text>
              </FormLabel>
              <InputGroup
                display={'flex'}
                alignItems={'flex-start'}
                flexDir={{ base: 'column', md: 'row', xl: 'column' }}
                flexWrap={'1'}
              >
                {/* <Icon as={BsListStars} mr={2} boxSize={'20px'} /> */}
                <FormControl
                  maxW={{ base: '100%', md: '30%', xl: '100%' }}
                  pr={{ base: 0, md: 4, xl: 0 }}
                >
                  <FormLabel
                    display={'flex'}
                    alignContent={'center'}
                    lineHeight={'22px'}
                    mb={1}
                  >
                    <Text as={'span'} fontSize={'sm'}>
                      Photos
                    </Text>
                  </FormLabel>
                  <MultiSelect
                    isMulti={false}
                    name={'num-residential-photos-select'}
                    options={selectNumResidentialItems}
                    onChange={handleNumResidential}
                    selectComponents={customSelectComponents}
                    value={selectedNumResidential}
                  />
                </FormControl>
                <FormControl mt={{ base: 4, md: 0, xl: 4 }}>
                  {/* <FormLabel>Services</FormLabel> */}

                  <FormLabel
                    display={'flex'}
                    alignContent={'center'}
                    alignItems={'end'}
                    lineHeight={'22px'}
                    mb={1}
                  >
                    <Text as={'span'} fontSize={'sm'}>
                      Additional Options
                    </Text>
                  </FormLabel>
                  <MultiSelect
                    name={'residential-select'}
                    options={residentialOptions}
                    onChange={handleResidentialInput}
                    placeHolder={'Real Estate  Options'}
                    selectComponents={customSelectComponents}
                    value={selectedResidentialOptions}
                  />
                </FormControl>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                display={'flex'}
                alignContent={'center'}
                alignItems={'end'}
                lineHeight={'22px'}
                mb={{ base: 4 }}
              >
                <Icon as={IoLocationSharp} boxSize={'22px'} mr={1} />

                <Text as={'span'} fontSize={'lg'}>
                  Location
                </Text>
              </FormLabel>

              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                overflow={'hidden'}
                spacing={{ base: 4 }}
              >
                <FormControl>
                  <FormLabel
                    display={'flex'}
                    alignContent={'center'}
                    lineHeight={'22px'}
                    mb={1}
                  >
                    <Text as={'span'} fontSize={'sm'}>
                      Address
                    </Text>
                  </FormLabel>
                  <Input
                    borderRadius={'none'}
                    focusBorderColor={'brand.accent'}
                    name='address'
                    onChange={(e) =>
                      handleLocationInput({ address: e.target.value })
                    }
                    // placeholder='Address number'
                    type='text'
                    value={location.address}
                  />
                </FormControl>
                <FormControl mt={{ base: 4, sm: 0 }}>
                  {/* <FormLabel>Services</FormLabel> */}

                  <FormLabel
                    display={'flex'}
                    alignContent={'center'}
                    alignItems={'end'}
                    lineHeight={'22px'}
                    mb={1}
                  >
                    <Text as={'span'} fontSize={'sm'}>
                      City
                    </Text>
                  </FormLabel>
                  <Input
                    borderRadius={'none'}
                    focusBorderColor={'brand.accent'}
                    name='city'
                    onChange={(e) =>
                      handleLocationInput({ city: e.target.value })
                    }
                    // placeholder='City number'
                    type='text'
                    value={location.city}
                  />
                </FormControl>
              </SimpleGrid>
              <SimpleGrid
                columns={{ base: 1, sm: 2 }}
                overflow={'hidden'}
                spacing={{ base: 4 }}
                mt={4}
              >
                {/* 
                  TODO: 
                  pickup here,
                    set breakpoints fot state/zip split
                    state label bp sm abb, lg full name etc

                */}
                <FormControl>
                  <FormLabel
                    display={'flex'}
                    alignContent={'center'}
                    lineHeight={'22px'}
                    mb={1}
                  >
                    <Text as={'span'} fontSize={'sm'}>
                      State
                    </Text>
                  </FormLabel>
                  <MultiSelect
                    isMulti={false}
                    onChange={(e) => handleStateSelect(e)}
                    options={usStates}
                    value={location.state}
                  >
                    {/* {usStates.map(s => (
                      <option key={s.abbreviation} value={s.abbreviation}>
                        {' '}
                        {s.name}
                      </option>
                    ))} */}
                  </MultiSelect>
                  {/* <Input
                    borderRadius={'none'}
                    focusBorderColor={'brand.accent'}
                    name='state'
                    onChange={(e) =>
                      handleLocationInput({ state: e.target.value })
                    }
                    // placeholder='Address number'
                    type='text'
                    value={location.state}
                  /> */}
                </FormControl>
                <FormControl mt={{ base: 4, sm: 0 }}>
                  {/* <FormLabel>Services</FormLabel> */}

                  <FormLabel
                    display={'flex'}
                    alignContent={'center'}
                    alignItems={'end'}
                    lineHeight={'22px'}
                    mb={1}
                  >
                    <Text as={'span'} fontSize={'sm'}>
                      Zip code
                    </Text>
                  </FormLabel>
                  <Input
                    borderRadius={'none'}
                    focusBorderColor={'brand.accent'}
                    name='zipcode'
                    onChange={(e) =>
                      handleLocationInput({ zipcode: e.target.value })
                    }
                    // placeholder='Zipcode number'
                    type='text'
                    value={location.zipcode}
                  />
                </FormControl>
              </SimpleGrid>
            </FormControl>
          </>
        )}
        {/* {showVirtualStagingSelect && (
          <FormControl>
         

            <InputGroup display={'flex'} alignItems={'center'}>
              <Icon as={BsListStars} mr={2} boxSize={'20px'} />
              <MultiSelect
                name={'virtual-staging-select'}
                placeHolder={'Virtual Staging Options'}
                options={virtualStagingOptions}
                onChange={handleVirtualStagingInput}
                value={selectedVirtualStaging}
              />
            </InputGroup>
          </FormControl>
        )} */}

        {showDateSelect && (
          <FormControl>
            <FormLabel
              display={'flex'}
              alignContent={'center'}
              alignItems={'end'}
              lineHeight={'22px'}
              mb={4}
            >
              <Icon as={BiCalendarEvent} boxSize={'20px'} mr={1} />
              {/* <Icon as={LuCalendarClock} boxSize={'20px'} mr={1} /> */}

              <Text as={'span'} fontSize={'lg'}>
                Desired Appointment
              </Text>
            </FormLabel>
            {/* <DatePicker
       
          //TODO: move to comp
          {...datepickerProps}
          /> */}
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              overflow={'hidden'}
              spacing={{ base: 4 }}

              // textAlign={'left'}
            >
              {/* <Icon as={BsListStars} mr={2} boxSize={'20px'} /> */}
              <FormControl mt={{ base: 4, md: 0 }}>
                <FormLabel
                  display={'flex'}
                  alignContent={'center'}
                  lineHeight={'22px'}
                  mb={1}
                >
                  <Text as={'span'} fontSize={'sm'}>
                    Date
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  min={new Date().toISOString().split('T')[0]}
                  name='date'
                  onBlur={handleDateInput}
                  onChange={handleDateInput}
                  type={'date'}
                  value={dateInput}
                />
              </FormControl>
              <FormControl mt={{ base: 4, md: 0 }}>
                {/* <FormLabel>Services</FormLabel> */}

                <FormLabel
                  display={'flex'}
                  alignContent={'center'}
                  alignItems={'end'}
                  lineHeight={'22px'}
                  mb={1}
                >
                  <Text as={'span'} fontSize={'sm'}>
                    Time
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='date'
                  onBlur={handleTimeInput}
                  onChange={handleTimeInput}
                  type={'time'}
                  value={timeInput}
                />
              </FormControl>
            </SimpleGrid>
          </FormControl>
        )}
        <FormControl>
          {/* <FormLabel>Message</FormLabel> */}

          <Textarea
            borderRadius={'none'}
            focusBorderColor={'brand.accent'}
            name='message'
            onChange={handleMessageInput}
            placeholder='Additional messages.'
            rows={4}
            resize='none'
            value={messageInput}
          />
        </FormControl>
        <CaptchaWidget onDone={onCaptchaDone} widget={captachaWidget} />
        {/* container={captchaContainer}
          widget={captachaWidget} */}

        <Box d={'flex'} alignContent={'center'} minHeight={'75px'} w={'full'}>
          {submitResult && (
            <AlertWrapper
              {...submitResult}
              onClick={() => setSubmitResult(null)}
            ></AlertWrapper>
          )}
        </Box>
        <Button
          colorScheme={'brand'}
          leftIcon={<Icon as={MdSend} mr={2} />}
          isLoading={submitStatus === 'loading'}
          onClick={handleSubmitButton}
          pt={1}
          // mt={'auto'}
          // mt={{ base: 0, md: 4 }}
          rounded={'none'}
          size={{ base: 'lg' }}
          textTransform={'uppercase'}
          transition={'all 0.2s ease-in-out'}
          type={'submit'}
          variant='outline'
          w={'full'}
          // bg={useColorModeValue('gray.900', 'gray.50')}
          // color={useColorModeValue('white', 'gray.900')}
          _hover={{
            boxShadow: 'lg',
            borderColor: 'brand.accent',
            borderWidth: '2.5px',
            color: 'brand.accent',
          }}
        >
          SUBMIT
        </Button>
      </VStack>
    </Box>
  )
}

export default ContactForm  
