import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react'
import './style.css'
const AlertWrapper = ({
  message = 'Unknown error encountered',
  onClick,
  status = 'error',
  title,
}) => {
  return (
    <Alert onClick={onClick} status={status} className={'flashit'}>
      <AlertIcon />
      <Box>
        <AlertTitle textTransform={'uppercase'}>{title || status}</AlertTitle>
        <AlertDescription>{message}</AlertDescription>
      </Box>
    </Alert>
  )
}

export default AlertWrapper
