import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorMode,
} from '@chakra-ui/react'

import { BiCalendarCheck } from 'react-icons/bi'
import { BsPersonFillAdd } from 'react-icons/bs'
import { FaCalendarCheck } from 'react-icons/fa'
import { MdErrorOutline, MdMarkEmailRead, MdOutlineLayersClear } from 'react-icons/md'

import ContactOverview from '../contact-overview'
import EventTile from '../event-tile'
import EventTileDetails from '../event-tile-details/index.js'

import { updateContact } from '../../services/contact'
import { timestampHuman, timestampSql } from '../../services/format'
import { act } from 'react-dom/test-utils'

const ManageEvents = () => {
  const { colorMode } = useColorMode()

  const [actionState, setActionState] = useState({})
  const [contacts, setContacts] = useState({})
  const [contactAdding, setContactAdding] = useState({})
  const [events, setEvents] = useState([])
  const [eventConfirming, setEventConfirming] = useState({})
  const [eventsConfirmed, setEventsConfirmed] = useState([])
  const [eventsHistory, setEventsHistory] = useState([])
  const [eventsInqueries, setEventsInqueries] = useState([])
  const [eventsRequested, setEventsRequested] = useState([])

  const addEventToCalendar = () => {
    console.log('add event to calendar')
  }

  const approveAllPending = () => {
    console.log('approve all pending events')
  }

  const approveEvent = event => {
    console.log('approve event')
    console.log(event)
    setEventConfirming(event)
  }

  const approveEventConfirm = event => {
    console.log('confirm approve event')
    console.log(event)
    setActionState({ ...actionState, confirmingEvent: { status: 'loading' } })
    const datetime = timestampSql()
    const date = timestampSql(undefined, false)
    event.conf_datetime = datetime
    event.conf_date = date
    setTimeout(() => {
      const updatedEvents = [...events?.filter(e => e.id !== event.id), event]
      setEvents(updatedEvents)
      setActionState({ ...actionState, confirmingEvent: { status: '' } })
      setEventConfirming({})
    }, 1500)
  }

  const archiveEvent = event => {
    console.log('archive event')
    console.log(event)
    if (!event?.id) {
      console.error('EVENT ID NOT FOUND!')
      return
    }
    setActionState({ ...actionState, [event.id]: { status: 'archiving' } })
    setTimeout(() => {
      event.archivedAt = timestampSql()
      const updatedEvents = [...events?.filter(e => e.id !== event.id), event]
      setEvents(updatedEvents)
      setActionState({ ...actionState, [event?.id]: '' })
    }, 1500)
  }

  const cancelEvent = () => {
    console.log('cancel event')
  }

  const deleteEvent = event => {
    console.log('delete event')
    console.log(event)
    if (!event?.id) {
      console.error('EVENT ID NOT FOUND!')
      return
    }
    setActionState({ ...actionState, [event.id]: { status: 'deleting' } })
    setTimeout(() => {
      const updatedEvents = [...events?.filter(e => e.id !== event.id)]
      setEvents(updatedEvents)
      setActionState({ ...actionState, [event?.id]: '' })
    }, 1500)
  }

  const editEvent = () => {
    console.log('edit event')
  }

  const markAllViewed = () => {
    console.log('mark all viewed')
  }

  const onContactModalClose = () => {
    setContactAdding({})
    // create key for contact loading/error states in actionState
    setLoadingState('')
  }

  const saveContact = async () => {
    console.log('save contact')
    if (!contactAdding) {
      console.error('contact adding not found!')
      return
    }
    setLoadingState('addingContact')
    //hit API, updated contact returned/or just success 1
    console.log('PICK UP HERE======')

    try {
      const contact = { id: contactAdding.id, is_contact: true }
      const res = await updateContact(contact)
      if (res?.error) {
        throw new Error(res)
      }

      if (!res?.id) {
        throw new Error({ message: 'Did not receive expected server response. Possible failure. Refresh and try again. ' })
      }
      setContacts({ ...contacts, [contact.id]: res })
      onContactModalClose()
      console.log(res)
      console.log('show success message!')
    } catch (e) {
      console.log(e?.message || e || 'Error saving contact')
    } finally {
      setLoadingState('')
    }
  }

  const setLoadingState = e => {
    console.log('TEMP FUNC TO AVOID ERRORS DURING REFACTOR')
    console.log(e)
    console.log('------')
  }

  //  PULL EVENTS FROM API
  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const URL = process.env.REACT_APP_API_URL + 'manage/event'
        const resp = await fetch(URL)
        const data = await resp.json()

        if (data?.contacts) {
          setContacts(data.contacts)
        }
        if (data?.events?.length) {
          setEvents(data.events)
        }
      } catch (e) {
        console.log('error caught fetching event list')
        console.log(e)
      }
    }
    fetchPageData()
  }, [])

  useEffect(() => {
    const now = new Date().getTime()
    const eventsDesc = events.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
    const eventsHistory = eventsDesc.filter(e => e?.archivedAt || e?.conf_datetime < now)
    setEventsHistory(eventsHistory)
    const inqueries = eventsDesc.filter(e => e?.service === 'general' && !e?.archivedAt)
    setEventsInqueries(inqueries)
    const requested = eventsDesc.filter(e => e?.service !== 'general' && !e?.conf_date)
    setEventsRequested(requested)
    const eventsConfirmed = eventsDesc.filter(e => !!e?.conf_date)
    setEventsConfirmed(eventsConfirmed)
  }, [events])

  return (
    <Box>
      {!!eventsInqueries.length && (
        <Box mt={8} mb={36} textAlign={'left'}>
          <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
            <Text color={'brand.accent'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
              General Inquries
            </Text>
            <Button
              borderRadius={'none'}
              borderColor={'green'}
              color={'green'}
              onClick={markAllViewed}
              leftIcon={<MdMarkEmailRead />}
              variant={'outline'}
              _hover={{
                bgColor: 'black',
                borderColor: 'brand.accent',
                color: 'brand.accent',
              }}
            >
              MARK ALL READ
            </Button>
          </Flex>
          {eventsInqueries.map(event => (
            <EventTile
              key={event.id}
              actionState={actionState?.[event.id]}
              addContact={contact => setContactAdding(contact)}
              approveEvent={approveEvent}
              archiveEvent={archiveEvent}
              deleteEvent={deleteEvent}
              editEvent={editEvent}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              eventType={'general'}
            />
          ))}
        </Box>
      )}
      {!!eventsRequested.length && (
        <Box mt={8} mb={36} textAlign={'left'}>
          <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
            <Text color={'yellow'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
              Pending Requests
            </Text>
            <Button
              borderRadius={'none'}
              borderColor={'yellow'}
              color={'yellow'}
              onClick={approveAllPending}
              leftIcon={<FaCalendarCheck />}
              variant={'outline'}
              _hover={{
                bgColor: 'black',
                borderColor: 'brand.accent',
                color: 'brand.accent',
              }}
            >
              CONFIRM ALL
            </Button>
          </Flex>
          {eventsRequested.map(event => (
            <EventTile
              key={event.id}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              eventType={'pending'}
              addContact={contact => setContactAdding(contact)}
              approveEvent={approveEvent}
              deleteEvent={deleteEvent}
              editEvent={editEvent}
            />
          ))}
        </Box>
      )}

      <Box mt={24} textAlign={'left'}>
        <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
          <Text color={'green'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
            Confirmed Appointments
          </Text>
          <Button
            borderRadius={'none'}
            borderColor={'green'}
            color={'green'}
            onClick={approveAllPending}
            leftIcon={<FaCalendarCheck />}
            variant={'outline'}
            _hover={{
              bgColor: 'black',
              borderColor: 'brand.accent',
              color: 'brand.accent',
            }}
          >
            ADD NEW
          </Button>
        </Flex>
        {!eventsConfirmed.length ? (
          <Text fontSize={'lg'}>No upcoming appointments.</Text>
        ) : (
          eventsConfirmed.map(event => (
            <EventTile
              key={event.id}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              eventType={'confirmed'}
              addToCalendar={addEventToCalendar}
              cancelEvent={cancelEvent}
              editEvent={editEvent}
            />
          ))
        )}
      </Box>

      {!!eventsHistory.length && (
        <Box mt={24} textAlign={'left'}>
          <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
            <Text color={'brand.accent'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
              Event History
            </Text>
            <Text fontSize={'lg'} fontWeight={'bold'} textTransform={'uppercase'}>
              Filter/Search/Sort
            </Text>
          </Flex>
          {eventsHistory.map(event => (
            <EventTile key={event.id} event={{ ...event, contact: contacts?.[event?.contact_id] || {} }} deleteEvent={editEvent} eventType={'history'} />
          ))}
        </Box>
      )}

      {/* ADD CONTACT */}
      <AlertDialog isCentered isOpen={contactAdding?.id} onClose={onContactModalClose}>
        <AlertDialogOverlay>
          <AlertDialogContent maxH={600} maxW={600} width={'100%'}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              ADD NEW CONTACT
            </AlertDialogHeader>
            <AlertDialogBody>
              <Box display={'flex'} flexDir={'column'} alignItems={'start'} gap={2}>
                <Text as={'p'} textColor={'yellow'}>
                  The below contact will be added to the address book.
                </Text>
                <Text as={'p'} fontStyle={'italic'}>
                  Saved contact details can be added or modified from the client management page.
                </Text>
                <ContactOverview contact={contactAdding} />
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter display={'flex'} justifyContent={'space-between'} mt={4}>
              <Button colorScheme={'yellow'} onClick={() => onContactModalClose()} variant='outline'>
                CANCEL
              </Button>
              <Button
                colorScheme={'green'}
                isLoading={actionState?.['addingContact']?.status === 'loading'}
                rightIcon={<BsPersonFillAdd />}
                onClick={saveContact}
                variant='outline'
              >
                ADD CONTACT
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* CONFIRM REQUEST */}
      <AlertDialog isCentered isOpen={eventConfirming?.id} onClose={() => setEventConfirming({})}>
        <AlertDialogOverlay>
          <AlertDialogContent maxW={600} width={'100%'}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              CONFIRM APPOINTMENT
            </AlertDialogHeader>
            <AlertDialogBody>
                <Text as={'p'} textColor={'yellow'}>
                  Do you want to confirm the below appointment?
                  </Text>
                <Text as={'p'} display={'flex'} alignContent={'center'} fontSize={'xl'} fontWeight={'bold'} gap={2} textColor={'green'} my={4} >
                 <Icon as={BiCalendarCheck} boxSize={'8'} /> CONFIRMATION DATE: {timestampHuman(eventConfirming?.conf_datetime ?? eventConfirming?.req_datetime, true, true, 'short')}
                  </Text>


                  


                    <Text fontSize={'lg'} fontWeight={'bold'}>
                      Original Request
                    </Text>
              <Box display={'flex'} flexDir={'column'} alignItems={'start'} border={'solid 1.5px white'} borderRadius={'5px'} gap={2} p={4}>
                <EventTileDetails event={eventConfirming} expanded={false} />
                </Box>
                <Box display={'flex'} justifyContent={'center'} width={'100%'} py={6}>
                  <Checkbox defaultChecked iconSize='4rem'>
                    <Text fontSize={'lg'} fontWeight={'bold'}>
                      Send confirmation email
                    </Text>
                  </Checkbox>
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter display={'flex'} justifyContent={'space-between'} >
              <Button colorScheme={'yellow'} onClick={() => setEventConfirming({})} variant='outline'>
                CANCEL
              </Button>
              <Button
                colorScheme={'green'}
                isLoading={actionState?.['confirmingEvent']?.status === 'loading'}
                rightIcon={<BiCalendarCheck />}
                onClick={() => approveEventConfirm(eventConfirming)}
                variant='outline'
              >
                CONFIRM
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}

export default ManageEvents
