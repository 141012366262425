import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { FaSync } from 'react-icons/fa'
import { MdErrorOutline, MdOutlineLayersClear } from 'react-icons/md'
import ImageTile from '../image-tile'
import ImageUploadTile from '../image-upload-tile'
import ImageEditForm from '../image-edit-form'
import ModalDialog from '../controls/modal-dialog'
import TagsSelect from '../controls/tags-select'
import UploadForm from '../upload-form'

import { FaSearch, FaSortAlphaDown, FaSortAlphaDownAlt, FaSortAmountDown, FaSortAmountDownAlt, FaSortNumericDown, FaSortNumericDownAlt } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'

import { debounce } from '../../services/util'
import { fetchTag } from '../../services/tag'
import { createSrc, createSrcSet, fetchImages } from '../../services/image'

const tabs = ['gallery', 'library']
const sortOptions = [
  {
    icon: <FaSortAlphaDown />,
    label: 'Name Asc.',
    sort: (images = []) => images?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [],
    value: 'name-asc',
  },
  {
    icon: <FaSortAlphaDownAlt />,
    label: 'Name Desc.',
    sort: (images = []) => images?.sort((a, b) => b?.name?.localeCompare(a?.name)) || [],
    value: 'name-desc',
  },
  {
    icon: <FaSortNumericDown />,
    label: 'Date Asc.',
    sort: (images = []) => images?.sort((a, b) => new Date(a?.updatedAt)?.getTime() - new Date(b?.updatedAt).getTime()) || 0,
    value: 'date-asc',
  },
  {
    icon: <FaSortNumericDownAlt />,
    label: 'Date Desc.',
    sort: (images = []) => images?.sort((a, b) => new Date(b?.updatedAt)?.getTime() - new Date(a?.updatedAt).getTime()) || 0,
    value: 'date-desc',
  },
  {
    icon: <FaSortAmountDown />,
    label: 'Size Asc.',
    sort: (images = []) => images?.sort((a, b) => a?.size_bytes - b?.size_bytes) || 0,
    value: 'size-asc',
  },
  {
    icon: <FaSortAmountDownAlt />,
    label: 'Size Desc.',
    sort: (images = []) => images?.sort((a, b) => b?.size_bytes - a?.size_bytes) || 0,
    value: 'size-desc',
  },
]

// move to comp
const ImageError = ({ file, errors }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      key={file.name}
      p={2}
      textAlign={'left'}
      w={'100%'}
      whiteSpace={'nowrap'}
      _hover={{
        borderColor: 'brand.accent',
      }}
    >
      <Icon as={MdErrorOutline} color={'red'}></Icon>
      <Flex flexDirection={'column'} pl={2}>
        <Text as={'p'} fontSize={'md'} fontWeight={'bold'}>
          {file.name}
        </Text>
        <Text as={'p'}>{errors[0].message}</Text>
      </Flex>
    </Box>
  )
}

const ManageImageLibrary = () => {
  const { colorMode } = useColorMode()
  const [tags, setTags] = useState([])
  const [tagsSelected, setTagsSelected] = useState([])
  const [tagsTempSelected, setTagsTempSelected] = useState([])

  const [filesAccepted, setFilesAccepted] = useState([])
  const [filesRejected, setFilesRejected] = useState([])
  const [filesStatusState, setFilesStatusState] = useState({})
  const [images, setImages] = useState([])
  const [imageFilter, setimageFilter] = useState('all')
  const [imageSearch, setimageSearch] = useState('')
  const [imageSort, setimageSort] = useState(sortOptions[0])
  const [imagesDisplayed, setImagesDisplayed] = useState([])
  const [imageEditing, setImageEditing] = useState({})
  const [loadingState, setLoadingState] = useState('')
  const [uploadEditing, setUploadEditing] = useState([])
  const [overridePending, setOverridePending] = useState(true)
  //modal
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: closeEditModal } = useDisclosure()
  const { isOpen: isViewModalOpen, onOpen: onViewModalOpen, onClose: closeViewModal } = useDisclosure()

  // const customSelectComponents = {
  //   Option: ({ children, ...props }) => (
  //     <chakraComponents.Option {...props}>
  //       <Box
  //         color={props.isSelected && 'brand.accent'}
  //         display={'flex'}
  //         justifyContent={'space-between'}
  //         w={'100%'}
  //       >
  //         {/* <p> {JSON.stringify(props.isSelected)}</p> */}
  //         <Box
  //           display={'flex'}
  //           flexDir={'column'}
  //           justifyContent={'space-between'}
  //           w={'100%'}
  //         >
  //           <Text fontWeight={'bold'}> {props?.data?.label}</Text>
  //           {props?.data?.description ?? (
  //             <Text fontSize={1}>{props?.data?.description}</Text>
  //           )}
  //         </Box>
  //         <Text> {props?.data?.costLocal ?? ''}</Text>
  //       </Box>
  //     </chakraComponents.Option>
  //   ),
  // }

  const clearFilesRejected = () => {
    // const acceptedFiles = [...filesAccepted]
    setFilesRejected([])
    // setFilesAccepted(acceptedFiles)
  }

  const deleteImage = async image => {
    console.log(image)
    if (!image.id) {
      console.error('show id not found error')
    }

    setFilesStatusState(prevState => ({
      ...prevState,
      [image.id]: {
        status: 'deleting',
      },
    }))

    const URL = process.env.REACT_APP_API_URL + 'manage/image/' + image.id

    try {
      const resp = await fetch(URL, {
        method: 'DELETE',
      })
      const respData = await resp.json()

      if (respData?.status === 'error') {
        throw new Error(respData?.message)
      }

      setImages([...images.filter(i => i.id !== image.id)])
      setFilesStatusState(prevState => ({
        ...prevState,
        [image.id]: {
          message: 'Image updated successfully.',
          status: 'success',
        },
      }))
    } catch (e) {
      console.log('error saving image changes')
      console.log(e)
      setFilesStatusState(prevState => ({
        ...prevState,
        [image.id]: {
          message: e?.message || e || 'error deleting image.',
          status: 'error',
        },
      }))
    } finally {
    }
  }

  const deletePendingUpload = image => {
    //TODO: Seperate logic?
    const isUpload = !!image?.file
    const existingImages = isUpload ? [...filesAccepted.filter(i => i.id !== image.id)] : [...images.filter(i => i.id !== image.id)]
    const sortedImages = existingImages.sort((a, b) => a.name.localeCompare(b.name))
    if (isUpload) {
      setFilesAccepted(sortedImages)
    } else {
      setImages(sortedImages)
    }
  }

  const editImage = image => {
    setImageEditing(image)
    onEditModalOpen()
  }

  const editPendingUpload = image => {
    setUploadEditing(image)
    onEditModalOpen()
  }

  const filterImages = () => {
    if (!images?.length) {
      setImagesDisplayed([])
      return
    }
    let imageList = [...images]

    // Filter by search input
    if (imageSearch?.length) {
      const searchLower = imageSearch?.toLocaleLowerCase() || imageSearch
      // console.log(searchLower)
      imageList = imageList.filter(i => i?.name?.toLowerCase()?.includes(searchLower) || i?.file_name?.toLowerCase()?.includes(searchLower))
    }

    if (!tagsSelected?.length) {
      //TODO: display all images... too slow?
      // setImagesDisplayed(images)
      imageList = imageList.filter(image => !image?.tags?.length)
      setImagesDisplayed(imageList)
      // return
    } else {
      const tagsSelectedId = tagsSelected.map(t => t.id).sort((a, b) => a - b)
      //Match any selected tag
      const matchAny = () => {
        return imageList
          .filter(image => {
            return image?.tags?.filter(tag => tagsSelected?.find(selectedTag => selectedTag.id === tag))?.length > 0
          })
          .map(i => {
            return { ...i, tags: i?.tags?.map(t => tags[t]) || [] }
          })
      }

      //Match all selected tags
      const matchAll = () =>
        // this will include images with additional tags than those selected
        imageList
          .filter(image => {
            return image?.tags?.length && tagsSelectedId.every(tagId => image.tags.includes(tagId))
          })
          .map(i => {
            return { ...i, tags: i?.tags?.map(t => tags[t]) || [] }
          })

      // This will match exactly selected tags
      const matchExact = () =>
        imageList
          .filter(image => {
            return image?.tags?.length && image.tags.sort((a, b) => a - b).join('') === tagsSelectedId.join('')
          })
          .map(i => {
            return { ...i, tags: i?.tags?.map(t => tags[t]) || [] }
          })

      const filters = {
        all: matchAll,
        any: matchAny,
        exact: matchExact,
      }
      imageList = filters[imageFilter]()
    }

    // SORT ORDER
    if (imageSort?.sort) {
      imageList = imageSort.sort([...imageList])
    }
    setImagesDisplayed(imageList)
  }

  const onFilesDrop = (acceptedFiles = [], rejectedFiles = [], e) => {
    // console.log('ACCEPTED FILES')
    let accepted = acceptedFiles.map(f => {
      const fileNameArr = f.name.split('.')
      const ext = fileNameArr.pop()
      const name = fileNameArr.join('.')
      const fileData = {
        ext,
        id: crypto.randomUUID(),
        file: f,
        name,
        size: f?.size || 0,
        tags: [...tagsSelected],
      }
      return fileData
    })
    //map over rejected, atleast add id?
    if (!overridePending) {
      // console.log('replace disabled, append')
      accepted = [...filesAccepted, ...accepted]
      rejectedFiles = [...filesRejected, ...rejectedFiles]
    }
    const sorted = accepted.sort((a, b) => a.name.localeCompare(b.name))
    setFilesAccepted(sorted)
    //normalize rejected
    console.log(rejectedFiles)
    setFilesRejected(rejectedFiles)
  }

  const onEditModalClose = () => {
    setUploadEditing({})
    setImageEditing({})
    closeEditModal()
  }

  const onTagSelectChange = e => {
    setTagsSelected(e)
  }

  const onSaveImageEditing = async image => {
    // extract only what we can edit

    if (!image.id) {
      console.error('show id not found error')
    }
    if (!image.name) {
      console.error('show name not found error (should not be possible)')
    }
    setFilesStatusState(prevState => ({
      ...prevState,
      [image.id]: {
        status: 'loading',
      },
    }))

    const URL = process.env.REACT_APP_API_URL + 'manage/image'

    const imageTags = image?.tags?.map(t => t.id) || []
    // console.log(imageTags)
    const postData = {
      id: image.id,
      name: image.name,
      tags: imageTags,
    }

    try {
      const resp = await fetch(URL, {
        method: 'PUT',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      const respData = await resp.json()

      if (respData?.status === 'error') {
        throw new Error(respData?.message)
      }

      const imageData = { ...image, name: image.name, tags: imageTags, updatedAt: respData.message || '' }
      // console.log(imageData)
      // console.log([...images.filter(i => i.id !== imageData.id), imageData])
      setImages([...images.filter(i => i.id !== imageData.id), imageData])
      setFilesStatusState(prevState => ({
        ...prevState,
        [image.id]: {
          message: 'Image updated successfully.',
          status: 'updated',
        },
      }))
    } catch (e) {
      console.log('error saving image changes')
      console.log(e)
      setFilesStatusState(prevState => ({
        ...prevState,
        [image.id]: {
          message: e?.message || e || 'error saving image changes.',
          status: 'error',
        },
      }))
    } finally {
      //close modal
      closeEditModal()
    }
  }

  const onSaveUploadImage = image => {
    // console.log(image)
    if (!image.id) {
      return
    }
    //Until we determine how we are going to differentiate library/upload images
    //for now just see if image has file property, assuming is upload blob
    //TODO: If library photo (pre existing)
    //upload changes to api, on success return updated file data, filter images, add new image
    const isUpload = !!image?.file

    const existingImages = image?.file ? [...filesAccepted.filter(i => i.id !== image.id)] : [...images.filter(i => i.id !== image.id)]
    const updatedImages = [...existingImages, image]
    const sortedImages = updatedImages.sort((a, b) => a.name.localeCompare(b.name))
    if (isUpload) {
      setFilesAccepted(sortedImages)
    } else {
      setImages(sortedImages)
    }
    setUploadEditing(null)
    closeEditModal()
  }

  const removeAllPendingUploads = () => {
    setFilesAccepted([])
  }

  const showPreview = image => {
    console.log('show prev')
    console.log('import lightbox.. ')
    console.log(image)
  }

  const syncTags = () => {
    setLoadingState('syncing-tags')
    fetchTag()
      .then(tags => {
        // console.log(tags)
        if (tags?.length) {
          const tagsById =
            tags?.reduce((acc, curr) => {
              return { ...acc, [curr.id.toString()]: { ...curr } }
            }, {}) || {}
          setTags(tagsById)
        }
      })
      .catch(e => console.log(e.message || 'error fetching tags from gallery'))
      .finally(() => setLoadingState(''))
  }

  const updateTags = updatedTags => {
    // console.log(updatedTags)
    //TODO: what do we do on tag changes?
    //remove images with removed tags?
    //change input?
    //separate add/remove tabs?
    setTags([...updatedTags])
  }

  const uploadItem = async item => {
    //TODO: MOVE TO ENV
    // const URL = 'https://api.manuelpellon.com/upload'
    if (!item) {
      console.log('no item passed to uploadItem')
      return
    }
    const URL = process.env.REACT_APP_API_URL + 'manage/upload'
    // const URL = 'http://localhost:3030/upload'

    setFilesStatusState(prevState => ({
      ...prevState,
      [item.id]: {
        message: 'file upload in progress',
        status: 'loading',
      },
    }))
    const formData = new FormData()
    formData.append('file', item.file)
    formData.append('name', item.name)
    formData.append('tags', JSON.stringify(item?.tags?.map(t => t.id) || [])) //todo, passed array, stringify 'tag1,tag2,etc'
    try {
      const resp = await fetch(URL, {
        method: 'POST',
        body: formData,
      })
      const respData = await resp.json()

      if (respData?.status === 'error') {
        throw new Error(respData?.message)
      }

      if (!respData?.id) {
        throw new Error('Unexpected server response.')
      }

      setFilesStatusState(prevState => ({
        ...prevState,
        [item.id]: {
          message: 'Image uploaded successfully.',
          status: 'success',
        },
      }))
      respData.src = createSrc(respData?.file_name)
      respData.srcSet = createSrcSet(respData?.file_name)

      setImages([...images, respData])
    } catch (e) {
      console.log('error received uploading file')
      console.log(e)
      setFilesStatusState(prevState => ({
        ...prevState,
        [item.id]: {
          message: e?.message || e || 'error encountered.',
          status: 'error',
        },
      }))
    }
  }

  const tagSelectHelperText = () => {
    if (!tagsSelected?.length && !imagesDisplayed?.length) {
      return 'No tags selected'
    }

    let text = `${imagesDisplayed.length} image${imagesDisplayed.length !== 1 ? 's' : ''} displayed`
    if (!tagsSelected.length) {
      text += ' without a tag'
    }
    return text
  }

  const uploadAll = () => {
    console.log('uploadAll')
    for (const idx in filesAccepted) {
      setTimeout(async () => {
        await uploadItem(filesAccepted[idx])
      }, 700)
    }
  }

  useEffect(() => {
    syncTags()
    fetchImages()
      .then(images => setImages(images))
      .catch(e => console.log(e.message || 'error fetching images from gallery'))
  }, [])

  useEffect(() => {
    debounce(filterImages)()
  }, [images, imageFilter, imageSearch, tagsSelected, imageSort])

  return (
    <>
      <Box alignContent={'start'} display={'grid'} gridTemplateColumns={{ base: '1fr', md: '1fr 3fr' }} pb={4}>
        <Box border='3px dashed' borderRadius={4} display={'flex'} alignItems={'center'}>
          <UploadForm onFilesDrop={onFilesDrop}></UploadForm>
        </Box>
        <Box display={'flex'} alignItems={'center'} ml={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }}>
          <TagsSelect
            helperText={tagSelectHelperText()}
            onAvailableTagsUpdated={updateTags}
            onChange={onTagSelectChange}
            tagOptions={Object.values(tags) || []}
            tags={tagsSelected}
          />

          <IconButton icon={<FaSync />} isRound={true} isLoading={loadingState === 'syncing-tags'} ml={2} variant={'ghost'} onClick={syncTags} />
        </Box>
      </Box>

      {/* upload items */}
      {/* TODO: MOVE TO COMP */}
      {(!!filesAccepted.length || !!filesRejected.length) && (
        <Box borderStyle='solid' borderColor={'brand.accent'} borderWidth={2} p={4} my={8}>
          <Text as={Heading} size={'sm'}>
            UPLOADS
          </Text>
          {!!filesRejected.length && (
            <Box mt={8} textAlign={'left'}>
              <Flex alignItems={'center'} justifyContent={'space-between'} mb={8}>
                <Text as={'p'} color={'red'} fontSize={'xl'} fontWeight={'bold'}>
                  The following files encountered errors and will not be included in the upload
                </Text>

                <Button
                  borderRadius={'none'}
                  onClick={clearFilesRejected}
                  leftIcon={<MdOutlineLayersClear />}
                  variant={'outline'}
                  _hover={{
                    bgColor: 'black',
                    color: 'brand.accent',
                  }}
                >
                  CLEAR ALL
                </Button>
              </Flex>
              {filesRejected.map(image => {
                return <ImageError key={image.file.name} errors={image?.errors || [{ message: 'Unkown error' }]} file={image.file} />
              })}
            </Box>
          )}
          {!!filesAccepted.length && (
            <Box textAlign={'left'} mt={8}>
              <Flex alignItems={'center'} justifyContent={'space-between'} mb={8}>
                <Text as={'p'} color={'green'} fontSize={'xl'} fontWeight={'bold'}>
                  The following files are pending upload. <br />
                </Text>
                {filesAccepted.length && (
                  <Box>
                    <Button
                      borderRadius={'none'}
                      disabled={!filesAccepted.length}
                      htmlFor={'upload-form'}
                      mr={4}
                      onClick={removeAllPendingUploads}
                      type={'submit'}
                      variant={'outline'}
                      _hover={{
                        bgColor: 'black',
                        color: 'brand.accent',
                      }}
                    >
                      CLEAR ALL
                    </Button>
                    <Button
                      disabled={!filesAccepted.length}
                      htmlFor={'upload-form'}
                      type={'submit'}
                      onClick={uploadAll}
                      borderRadius={'none'}
                      _hover={{
                        bgColor: 'black',
                        color: 'brand.accent',
                      }}
                    >
                      UPLOAD ALL
                    </Button>
                  </Box>
                )}
              </Flex>
              {filesAccepted.map(image => {
                return (
                  <ImageUploadTile
                    key={image.id}
                    image={image}
                    deleteItem={deletePendingUpload}
                    editItem={editPendingUpload}
                    status={filesStatusState[image.id] || ''}
                    tagData={tags}
                    uploadItem={uploadItem}
                  ></ImageUploadTile>
                )
              })}
            </Box>
          )}
        </Box>
      )}

      {/* IMAGE LIST
       */}
      <Box display={'flex'} alignItems={'center'} justifyItems={'space-between'} gap={6}>
        {/* SEARCH INPUT */}
        <InputGroup>
          <Input placeholder='Search' onInput={e => setimageSearch(e?.target?.value || '')} borderRadius={'md'} value={imageSearch} />
          <InputLeftElement>
            <Icon as={FaSearch} />
          </InputLeftElement>
          <InputRightElement>{imageSearch?.length ? <Icon as={MdClose} cursor={'pointer'} onClick={() => setimageSearch('')} /> : ''}</InputRightElement>
        </InputGroup>

        {/* SORT MENU */}
        <Menu>
          <Tooltip label={`Sort ${imageSort?.label}`} hasArrow placement={'top'}>
            <MenuButton aria-label='Sort Options' as={IconButton} icon={imageSort.icon} variant='outline' />
          </Tooltip>
          <MenuList>
            {sortOptions.map(option => (
              <MenuItem
                alignItems={'center'}
                color={option?.value === imageSort?.value ? 'brand.accent' : ''}
                key={option.value}
                icon={option.icon}
                onClick={() => setimageSort(option)}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        {/* TAG MATCH RADIO */}
        <Box display={'flex'} flexDir={'column'} alignItems={'flex-start'} py={4} whiteSpace={'nowrap'}>
          <Text fontWeight={'bold'} fontSize={'lg'}>
            Tag Match:
          </Text>
          <RadioGroup colorScheme='orange' isDisabled={!tagsSelected?.length} onChange={setimageFilter} value={imageFilter}>
            <Stack direction='row'>
              <Radio value={'all'} mr={1}>
                <Tooltip hasArrow label='Display images with all selected tags.' placement='top'>
                  All
                </Tooltip>
              </Radio>
              <Radio value={'any'} mr={1}>
                <Tooltip hasArrow label='Display images with any of the selected tags.' placement='top'>
                  Any
                </Tooltip>
              </Radio>
              <Radio value={'exact'} mr={1}>
                <Tooltip hasArrow label='Display images with only the selected tags.' placement='top'>
                  Exact
                </Tooltip>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
      </Box>

      {/* IMAGES */}

      {!!tagsSelected?.length && !imagesDisplayed?.length && (
        <Text as={'p'} fontSize={'2xl'} fontWeight={'bold'}>
          No images found
        </Text>
      )}
      {imagesDisplayed.map((image, idx) => {
        return (
          <ImageTile
            key={image.id + idx.toString()}
            image={image}
            deleteItem={deleteImage}
            editItem={editImage}
            status={filesStatusState[image.id] || ''}
            tagData={tags}
          ></ImageTile>
        )
      })}

      {/* IMAGE EDIT FORM */}
      <ModalDialog
        isOpen={isEditModalOpen}
        onOpen={onEditModalOpen}
        onClose={onEditModalClose}
        modalBody={
          <Box>
            <ImageEditForm image={imageEditing?.id ? imageEditing : uploadEditing} saveImage={imageEditing?.id ? onSaveImageEditing : onSaveUploadImage} tagData={tags} />
          </Box>
        }
        title={'EDIT IMAGE'}
      />
      <ModalDialog
        isOpen={isEditModalOpen}
        onOpen={onEditModalOpen}
        onClose={onEditModalClose}
        modalBody={
          <Box id='test'>
            <ImageEditForm image={imageEditing?.id ? imageEditing : uploadEditing} saveImage={imageEditing?.id ? onSaveImageEditing : onSaveUploadImage} tagData={tags} />
          </Box>
        }
        title={'EDIT IMAGE'}
      />
    </>
  )
}

export default ManageImageLibrary
